import {
    Alert,
    AlertTitle,
    Autocomplete,
    Avatar,
    Badge,
    Button,
    Card,
    CardContent,
    Checkbox,
    CircularProgress,
    DialogContentText,
    FormControlLabel,
    Icon,
    IconButton,
    Link,
    styled,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { QRCodeSVG } from 'qrcode.react';
import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import clsx from 'clsx';
import { selectUser, validate } from '../../store/shared/authSlice';
import apiService from '../../services/apiService';
import { showMessage } from '../../store/shared/messageSlice';
import ConfirmationDialog from '../../ui-components/ConfirmationDialog';
// import FileUploadButton from '../../ui-components/FileUploadButton';
import { selectPageForNoCouncil, selectPartnerDays, selectPartnerPrice } from '../../store/shared/configSlice';
import PartnerPaymentDialog from './PartnerPaymentDialog';
import countries from '../../countries';

const TextFieldNoRoundedRight = styled(TextField)({
    '& .MuiInputBase-root': {
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px'
    }
});
const TextFieldNoRounded = styled(TextField)({
    '& .MuiInputBase-root': {
        borderRadius: '0px'
    }
});
const AutocompleteNoRoundedRight = styled(Autocomplete)({
    '& .MuiInputBase-root': {
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px'
    }
});

export default function ProfilePage() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const pageForNoCouncil = useSelector(selectPageForNoCouncil);
    const partnerPrice = useSelector(selectPartnerPrice);
    const partnerDays = useSelector(selectPartnerDays);
    const [educations, setEducations] = useState(null);
    const [qrCodeEnabled, setQrCodeEnabled] = useState(false);
    const [qrCodeLoading, setQrCodeLoading] = useState(false);
    const [qrCode, setQrCode] = useState(null);
    const [education, setEducation] = useState(user?.education || null);
    const [firstname, setFirstname] = useState(user.firstname);
    const [lastname, setLastname] = useState(user.lastname);
    const [address, setAddress] = useState(user.address);
    const [city, setCity] = useState(user.city);
    const [phone, setPhone] = useState(user.phone);
    const [zip, setZip] = useState(user.zip);
    const [country, setCountry] = useState(user.country);
    const [email, setEmail] = useState(user.email);
    const [secondaryEmail, setSecondaryEmail] = useState(user.secondaryEmail || '');
    const [oldPassword, setOldPassword] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [loading, setLoading] = useState({});
    const [confirmPasswordDialogOpen, setConfirmPasswordDialogOpen] = useState(false);
    const [confirmEmailDialogOpen, setConfirmEmailDialogOpen] = useState(false);
    const [confirmSecondaryEmailDialogOpen, setConfirmSecondaryEmailDialogOpen] = useState(false);
    // const [selectAvatarOpen, setSelectAvatarOpen] = useState(false);
    // const [selectedFile, setSelectedFile] = useState(false);
    const [partnerPaymentDialogOpen, setPartnerPaymentDialogOpen] = useState(!!searchParams.get('onpay_number'));
    const { t } = useTranslation();

    useEffect(() => {
        apiService
            .get('/educations?populate=name&pagination[limit]=1000000000')
            .then(response => {
                setEducations(response.data.map(e => ({ ...e.attributes, id: e.id })));
            })
            .catch(console.error);
    }, []);

    useEffect(() => {
        if (qrCodeEnabled && user?.qr?.tokens) {
            let index = 0;
            const interval = setInterval(() => {
                setQrCode(`${user.qr.tokens[index]}:${user.id}`);
                index += 1;
                if (index >= user.qr.tokens.length) {
                    apiService.post('/users/me-qr').then(() => dispatch(validate()));
                }
            }, 500);

            return () => clearInterval(interval);
        }
        setQrCode(
            '01101000 01110100 01110100 01110000 01110011 00111010 00101111 00101111 01110111 01110111 01110111 00101110 01111001 01101111 01110101 01110100 01110101 01100010 01100101 00101110 01100011 01101111 01101101 00101111 01110111 01100001 01110100 01100011 01101000 00111111 01110110 00111101 01100100 01010001 01110111 00110100 01110111 00111001 01010111 01100111 01011000 01100011 01010001'
        );
        return () => {};
    }, [dispatch, user, qrCodeEnabled]);

    async function handleToggleQR() {
        if (user.role?.name?.toLowerCase() === 'member') {
            setQrCodeEnabled(oldState => !oldState);
            setQrCodeLoading(true);
            await apiService.post('/users/me-qr');
            await dispatch(validate());
            setQrCodeLoading(false);
        }
    }

    const roles = useMemo(() => {
        const _roles = [];
        const _title = user.role?.name?.toLowerCase();
        if (_title === 'unverified') {
            _roles.push({ name: t(_title.toUpperCase()), color: 'bg-red-500' });
        }
        if (_title === 'verified') {
            _roles.push({ name: t(_title.toUpperCase()), color: 'bg-green-500' });
        }
        if (_title === 'member') {
            _roles.push({ name: t(_title.toUpperCase()), color: 'bg-blue-500' });
        }
        if (_title === 'partner') {
            _roles.push({ name: t(_title.toUpperCase()), color: 'bg-blue-500' });
        }
        if (user.education) {
            _roles.push({ name: user.education.name, color: 'bg-blue-500' });
        }
        return _roles;
    }, [t, user.role?.name, user.education]);

    function handleNewsletterToggle(key) {
        setLoading(oldState => ({ ...oldState, [key]: true }));
        const changeTo = !user[key];
        apiService
            .put('/users/me', {
                [key]: changeTo
            })
            .then(() => {
                dispatch(validate());
                if (changeTo) {
                    dispatch(
                        showMessage({
                            message: t('NEWSLETTER_SUCCESS_ENABLED'),
                            variant: 'success'
                        })
                    );
                } else {
                    dispatch(
                        showMessage({
                            message: t('NEWSLETTER_SUCCESS_DISABLED'),
                            variant: 'success'
                        })
                    );
                }
            })
            .catch(e => {
                dispatch(showMessage({ message: e.response.data.error.message, variant: 'error' }));
            })
            .finally(() => setLoading(oldState => ({ ...oldState, [key]: false })));
    }

    function handleNameSave() {
        setLoading(oldState => ({ ...oldState, name: true }));
        apiService
            .put('/users/me', {
                firstname,
                lastname
            })
            .then(() => {
                dispatch(validate());
                dispatch(showMessage({ message: t('NAME_CHANGED_SUCCESS'), variant: 'success' }));
            })
            .catch(e => {
                dispatch(showMessage({ message: e.response.data.error.message, variant: 'error' }));
            })
            .finally(() => setLoading(oldState => ({ ...oldState, name: false })));
    }

    function handleAddressSave() {
        setLoading(oldState => ({ ...oldState, address: true }));
        apiService
            .put('/users/me', {
                address
            })
            .then(() => {
                dispatch(validate());
                dispatch(showMessage({ message: t('ADDRESS_CHANGED_SUCCESS'), variant: 'success' }));
            })
            .catch(e => {
                dispatch(showMessage({ message: e.response.data.error.message, variant: 'error' }));
            })
            .finally(() => setLoading(oldState => ({ ...oldState, address: false })));
    }

    function handleCitySave() {
        setLoading(oldState => ({ ...oldState, city: true }));
        apiService
            .put('/users/me', {
                city,
                zip
            })
            .then(() => {
                dispatch(validate());
                dispatch(showMessage({ message: t('CITY_CHANGED_SUCCESS'), variant: 'success' }));
            })
            .catch(e => {
                dispatch(showMessage({ message: e.response.data.error.message, variant: 'error' }));
            })
            .finally(() => setLoading(oldState => ({ ...oldState, city: false })));
    }

    function handlePhoneSave() {
        setLoading(oldState => ({ ...oldState, phone: true }));
        apiService
            .put('/users/me', {
                phone
            })
            .then(() => {
                dispatch(validate());
                dispatch(showMessage({ message: t('PHONE_CHANGED_SUCCESS'), variant: 'success' }));
            })
            .catch(e => {
                dispatch(showMessage({ message: e.response.data.error.message, variant: 'error' }));
            })
            .finally(() => setLoading(oldState => ({ ...oldState, phone: false })));
    }

    function handleCountrySave() {
        setLoading(oldState => ({ ...oldState, Country: true }));
        apiService
            .put('/users/me', {
                country
            })
            .then(() => {
                dispatch(validate());
                dispatch(showMessage({ message: t('COUNTRY_CHANGED_SUCCESS'), variant: 'success' }));
            })
            .catch(e => {
                dispatch(showMessage({ message: e.response.data.error.message, variant: 'error' }));
            })
            .finally(() => setLoading(oldState => ({ ...oldState, Country: false })));
    }

    function handleEducationSave() {
        setLoading(oldState => ({ ...oldState, education: true }));
        apiService
            .put('/users/me', {
                education: [education.id]
            })
            .then(() => {
                dispatch(validate());
                dispatch(showMessage({ message: t('EDUCATION_CHANGED_SUCCESS'), variant: 'success' }));
            })
            .catch(e => {
                dispatch(showMessage({ message: e.response.data.error.message, variant: 'error' }));
            })
            .finally(() => setLoading(oldState => ({ ...oldState, education: false })));
    }

    function handleEmailSave(result) {
        if (result) {
            setLoading(oldState => ({ ...oldState, email: true }));
            apiService
                .put('/users/me', {
                    email
                })
                .then(() => {
                    dispatch(validate());
                    dispatch(showMessage({ message: t('EMAIL_CHANGED_SUCCESS'), variant: 'success' }));
                })
                .catch(e => {
                    dispatch(showMessage({ message: e.response.data.error.message, variant: 'error' }));
                })
                .finally(() => setLoading(oldState => ({ ...oldState, email: false })));
        }
        setConfirmEmailDialogOpen(false);
    }

    function handleSecondaryEmailSave(result) {
        if (result) {
            setLoading(oldState => ({ ...oldState, secondaryEmail: true }));
            apiService
                .put('/users/me', {
                    secondaryEmail
                })
                .then(() => {
                    dispatch(validate());
                    dispatch(showMessage({ message: t('EMAIL_CHANGED_SUCCESS'), variant: 'success' }));
                })
                .catch(e => {
                    dispatch(showMessage({ message: e.response.data.error.message, variant: 'error' }));
                })
                .finally(() => setLoading(oldState => ({ ...oldState, secondaryEmail: false })));
        }
        setConfirmSecondaryEmailDialogOpen(false);
    }

    function handlePasswordSave(result) {
        if (result) {
            setLoading(oldState => ({ ...oldState, password: true }));
            apiService
                .put('/users/me', {
                    oldPassword,
                    password,
                    passwordConfirmation
                })
                .then(() => {
                    dispatch(validate());
                    dispatch(
                        showMessage({
                            message: t('PASSWORD_CHANGED_SUCCESS'),
                            variant: 'success'
                        })
                    );
                })
                .catch(e => {
                    dispatch(showMessage({ message: e.response.data.error.message, variant: 'error' }));
                })
                .finally(() => {
                    setLoading(oldState => ({ ...oldState, password: false }));
                    setConfirmPasswordDialogOpen(false);
                });
        } else {
            setConfirmPasswordDialogOpen(false);
        }
        setPassword('');
        setPasswordConfirmation('');
        setOldPassword('');
    }

    // async function handleAvatarClose(result) {
    //     if (result) {
    //         const body = new FormData();
    //         body.append('files', selectedFile);
    //         const response = await fetch(`${apiService.getUri()}/api/users/me-avatar`, {
    //             method: 'POST',
    //             body,
    //             headers: {
    //                 Authorization: `Bearer ${apiService.getAccessToken()}`
    //             }
    //         });
    //         dispatch(validate());
    //     }
    //     setSelectedFile(null);
    //     setSelectAvatarOpen(false);
    // }

    return (
        <div className="w-full min-h-screen bg-gray-200 p-4 md:p-10">
            <PartnerPaymentDialog open={partnerPaymentDialogOpen} onClose={() => setPartnerPaymentDialogOpen(false)} />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                    <Card className="p-2 md:p-6 text-center relative">
                        <div className="flex items-center justify-center mb-4">
                            {user?.avatar?.url ? (
                                <Avatar
                                    className="h-24 w-24"
                                    alt="user photo"
                                    src={apiService.getImage(user?.avatar?.url)}
                                />
                            ) : (
                                <Avatar className="h-24 w-24">{user?.firstname[0]}</Avatar>
                            )}
                        </div>
                        <div>
                            <h2 className="text-2xl font-bold">
                                {user.firstname} {user.lastname}
                            </h2>
                            <p className="text-gray-500 -mt-2">
                                {t('MEMBER_ID')}: {user.username}
                            </p>
                            {user.membershipExpiration && (
                                <p className="text-gray-500 -mt-4">
                                    {t('MEMBERSHIP_EXPIRE')}:{' '}
                                    {moment(user.membershipExpiration).format(t('MEDIUM_DATE_FORMAT'))}
                                </p>
                            )}
                        </div>
                        <CardContent>
                            <h3 className="text-xl font-semibold mb-2">{t('ROLES')}</h3>
                            <div className="space-x-2">
                                {roles.map(role => (
                                    <Badge
                                        key={role.name}
                                        className={`text-xs font-medium px-2.5 py-0.5 rounded ${role.color} text-white`}
                                    >
                                        {role.name}
                                    </Badge>
                                ))}
                            </div>
                            {user?.coupons?.filter(
                                coupon => coupon.amountLeft > 0 && moment(coupon.expiration).isAfter(moment())
                            )?.length > 0 && (
                                <>
                                    <div
                                        className="flex justify-center items-center gap-2 -mb-3"
                                        style={{ transform: 'translateX(20px)' }}
                                    >
                                        <h3 className="text-xl font-semibold">Klippekort</h3>
                                        <Tooltip title="Se alle dine klippekort">
                                            <IconButton size="small" onClick={() => navigate('/mine-klippekort')}>
                                                <OpenInNewIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <div className="space-x-2">
                                        {user?.coupons
                                            ?.filter(coupon => coupon.amountLeft > 0)
                                            ?.map(coupon => (
                                                <Badge className="text-xs font-medium px-2.5 py-0.5 rounded bg-green-600 text-white">
                                                    {coupon.amountLeft}x {coupon.coupon_type.name}
                                                </Badge>
                                            ))}
                                    </div>
                                </>
                            )}
                        </CardContent>
                    </Card>
                    {user.role?.name?.toLowerCase() === 'partner' && (
                        <Card className="p-6 mt-8 relative">
                            <div>
                                <h2 className="text-xl font-bold">Partner</h2>
                            </div>
                            {!user?.isPartnerPaid && (
                                <>
                                    <Alert severity="error" className="-mx-6">
                                        <AlertTitle>Abonnement påkrævet</AlertTitle>
                                        Abonnement er påkrævet, for at kunne tilgå partner funktioner.
                                    </Alert>

                                    <CardContent>
                                        <div className="flex items-center justify-center">
                                            <div className="flex flex-col items-center">
                                                <Typography className="text-2xl font-bold">
                                                    {partnerPrice} kr. / {partnerDays} dage
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className="flex justify-center mt-4">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => setPartnerPaymentDialogOpen(true)}
                                            >
                                                Køb abonnement
                                            </Button>
                                        </div>
                                    </CardContent>
                                </>
                            )}
                            {user?.isPartnerPaid && (
                                <CardContent>
                                    <div className="flex items-center justify-center">
                                        <div className="flex flex-col items-center">
                                            <Typography className="text-2xl font-bold">
                                                Dit abonnement udløber d.{' '}
                                                {moment(user.membershipExpiration).format(t('MEDIUM_DATE_FORMAT'))}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-center mt-4">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => setPartnerPaymentDialogOpen(true)}
                                        >
                                            Forlæng abonnement
                                        </Button>
                                        <Typography className="text-sm text-center mt-2">
                                            {partnerPrice} kr. for {partnerDays} dage
                                        </Typography>
                                    </div>
                                </CardContent>
                            )}
                        </Card>
                    )}
                    {user.role?.name?.toLowerCase() !== 'partner' && (
                        <Card className="p-2 md:p-6 mt-8 relative">
                            <div>
                                <h2 className="ml-4 md:ml-0 text-xl font-bold">{t('YOUR_COUNCIL')}</h2>
                            </div>
                            {!user?.education && (
                                <Alert severity="error" className="-mx-6 z-10 relative">
                                    <AlertTitle>{t('EDUCATION_REQUIRED')}</AlertTitle>
                                    {t('EDUCATION_CHANGED_DESCRIPTION')}
                                </Alert>
                            )}
                            {user?.education && !user?.education?.council && (
                                <Alert severity="info" className="-mx-6 z-10 relative">
                                    {t('NO_COUNCIL_CONNECTED')}{' '}
                                    <Link component={RouterLink} to={pageForNoCouncil?.data?.attributes?.uri}>
                                        {t('NO_COUNCIL_CLICK_HERE')}
                                    </Link>
                                </Alert>
                            )}
                            {user?.education?.council && (
                                <CardContent className="inline-block">
                                    <div className="flex flex-col">
                                        <Typography>
                                            {t('COUNCIL_FOR_YOUR_EDUCATION')} {user?.education?.name} {t('COUNCIL_IS')}{' '}
                                            <span className="font-bold">{user?.education?.council?.name}</span>
                                        </Typography>
                                        <div className="flex flex-col mt-2">
                                            {user?.education?.council?.room && (
                                                <Typography>
                                                    <span className="font-bold">{t('COUNCIL_ROOM')}:</span>{' '}
                                                    {user?.education?.council?.room}
                                                </Typography>
                                            )}
                                            {user?.education?.council?.email && (
                                                <Typography>
                                                    <span className="font-bold">{t('EMAIL')}:</span>{' '}
                                                    {user?.education?.council?.email}
                                                </Typography>
                                            )}
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            component={RouterLink}
                                            to={`/council/${user?.education?.council?.slug}`}
                                        >
                                            {t('COUNCIL_CLICK_TO_READ_MORE')}
                                        </Button>
                                    </div>
                                </CardContent>
                            )}
                            <Icon
                                className="absolute bottom-0 right-0 -m-12 opacity-25 text-gray-600"
                                style={{ fontSize: '16rem' }}
                            >
                                account_balance
                            </Icon>
                        </Card>
                    )}
                </div>
                <div>
                    <Card className="p-2 md:p-6 relative">
                        <div>
                            <h2 className="ml-4 md:ml-0 text-xl font-bold">{t('ACCOUNT_SETTINGS')}</h2>
                        </div>
                        {!user.education?.id && user.role?.name?.toLowerCase() !== 'partner' && (
                            <Alert severity="error" className="-mx-6">
                                <AlertTitle>{t('EDUCATION_REQUIRED')}</AlertTitle>
                                {t('EDUCATION_CHANGED_DESCRIPTION')}
                            </Alert>
                        )}
                        <CardContent className="z-10 relative">
                            <div className="flex w-full mt-4">
                                <TextFieldNoRoundedRight
                                    className="w-full"
                                    id="firstname"
                                    name="firstname"
                                    label={t('FIRSTNAME')}
                                    placeholder="John"
                                    required
                                    size="small"
                                    value={firstname}
                                    onChange={e => setFirstname(e.target.value)}
                                />
                                <TextFieldNoRounded
                                    className="w-full"
                                    id="lastname"
                                    name="lastname"
                                    label={t('LASTNAME')}
                                    placeholder="Doe"
                                    required
                                    size="small"
                                    value={lastname}
                                    onChange={e => setLastname(e.target.value)}
                                />
                                <LoadingButton
                                    className="rounded-l-none w-36 bg-white hover:bg-white"
                                    variant="outlined"
                                    color="primary"
                                    disabled={
                                        !firstname ||
                                        !lastname ||
                                        (firstname === user.firstname && lastname === user.lastname)
                                    }
                                    onClick={handleNameSave}
                                    loading={!!loading.name}
                                >
                                    {t('CHANGE')}
                                </LoadingButton>
                            </div>
                            <div className="flex w-full mt-4">
                                <TextFieldNoRoundedRight
                                    className="w-full"
                                    id="address"
                                    name="address"
                                    label={t('ADDRESS')}
                                    placeholder="Vejen 32"
                                    required
                                    size="small"
                                    value={address}
                                    onChange={e => setAddress(e.target.value)}
                                />
                                <LoadingButton
                                    className="rounded-l-none w-36 bg-white hover:bg-white"
                                    variant="outlined"
                                    color="primary"
                                    disabled={!address || !address || address === user.address}
                                    onClick={handleAddressSave}
                                    loading={!!loading.address}
                                >
                                    {t('CHANGE')}
                                </LoadingButton>
                            </div>
                            <div className="flex w-full mt-4">
                                <TextFieldNoRoundedRight
                                    className="w-full"
                                    id="zip"
                                    name="zip"
                                    label={t('ZIP')}
                                    placeholder="1234"
                                    required
                                    size="small"
                                    value={zip}
                                    onChange={e => setZip(e.target.value)}
                                />
                                <TextFieldNoRounded
                                    className="w-full"
                                    id="city"
                                    name="city"
                                    label={t('CITY')}
                                    placeholder="Byen"
                                    required
                                    size="small"
                                    value={city}
                                    onChange={e => setCity(e.target.value)}
                                />
                                <LoadingButton
                                    className="rounded-l-none w-36 bg-white hover:bg-white"
                                    variant="outlined"
                                    color="primary"
                                    disabled={!city || !zip || (city === user.city && zip === user.zip)}
                                    onClick={handleCitySave}
                                    loading={!!loading.city}
                                >
                                    {t('CHANGE')}
                                </LoadingButton>
                            </div>
                            <div className="flex w-full mt-4">
                                <TextFieldNoRoundedRight
                                    className="w-full"
                                    id="phone"
                                    name="phone"
                                    label={t('PHONE')}
                                    placeholder="12345678"
                                    size="small"
                                    value={phone}
                                    onChange={e => setPhone(e.target.value)}
                                />
                                <LoadingButton
                                    className="rounded-l-none w-36 bg-white hover:bg-white"
                                    variant="outlined"
                                    color="primary"
                                    disabled={phone === user.phone}
                                    onClick={handlePhoneSave}
                                    loading={!!loading.phone}
                                >
                                    {t('CHANGE')}
                                </LoadingButton>
                            </div>
                            <div className="flex w-full mt-4">
                                <AutocompleteNoRoundedRight
                                    options={countries.map(c => c.code)}
                                    value={country}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            setCountry(newValue);
                                        }
                                    }}
                                    getOptionLabel={option => countries.find(c => c.code === option)?.name}
                                    className="w-full"
                                    size="small"
                                    renderInput={params => <TextField {...params} label={t('COUNTRY')} />}
                                    disableClearable
                                />
                                <LoadingButton
                                    className="rounded-l-none w-36 bg-white hover:bg-white"
                                    variant="outlined"
                                    color="primary"
                                    disabled={!country || country === user.country}
                                    onClick={handleCountrySave}
                                    loading={!!loading.country}
                                >
                                    {t('CHANGE')}
                                </LoadingButton>
                            </div>
                            <div className="w-full flex mt-4">
                                <TextFieldNoRoundedRight
                                    className="w-full"
                                    id="email"
                                    name="email"
                                    label={
                                        user.role?.name?.toLowerCase() === 'partner'
                                            ? t('PROFILE_PARTNER_EMAIL')
                                            : t('PROFILE_EMAIL')
                                    }
                                    placeholder={user.email}
                                    required
                                    type="email"
                                    size="small"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    error={
                                        user.role?.name?.toLowerCase() !== 'partner' &&
                                        !email.endsWith(process.env.REACT_APP_PRIMARY_EMAIL_VALIDATION)
                                    }
                                    helperText={
                                        user.role?.name?.toLowerCase() !== 'partner' &&
                                        !email.endsWith(process.env.REACT_APP_PRIMARY_EMAIL_VALIDATION)
                                            ? `${t('EMAIL_VALIDATION_ERROR')} ${
                                                  process.env.REACT_APP_PRIMARY_EMAIL_VALIDATION
                                              }`
                                            : ''
                                    }
                                />
                                <LoadingButton
                                    className="rounded-l-none w-36"
                                    variant="outlined"
                                    color="primary"
                                    disabled={
                                        email === user.email ||
                                        (user.role?.name?.toLowerCase() !== 'partner' &&
                                            !email.endsWith(process.env.REACT_APP_PRIMARY_EMAIL_VALIDATION))
                                    }
                                    onClick={() => setConfirmEmailDialogOpen(true)}
                                    loading={!!loading?.email}
                                >
                                    {t('CHANGE')}
                                </LoadingButton>
                                <ConfirmationDialog
                                    open={confirmEmailDialogOpen}
                                    onClose={handleEmailSave}
                                    title={t('CHANGE_EMAIL_TITLE')}
                                    okButton={t('CONFIRM')}
                                    cancelButton={t('CANCEL')}
                                    loading={!!loading?.email}
                                    disabled={email !== confirmEmail}
                                >
                                    <Alert severity="error" className="mb-4">
                                        {t('YOU_WILL_BE_LOGGED_OUT_AFTER_CHANGING_YOUR_EMAIL')}
                                    </Alert>
                                    <DialogContentText>
                                        {t(
                                            'TO_CHANGE_YOUR_EMAIL_ADDRESS_PLEASE_ENTER_YOUR_NEW_EMAIL_ADDRESS_BELOW_AND_CLICK_CONFIRM'
                                        )}
                                    </DialogContentText>
                                    <TextField
                                        autoFocus
                                        required
                                        margin="dense"
                                        id="name"
                                        name="confirmEmail"
                                        label={t('TYPE_YOUR_NEW_EMAIL_AGAIN')}
                                        fullWidth
                                        variant="standard"
                                        value={confirmEmail}
                                        onChange={e => setConfirmEmail(e.target.value)}
                                    />
                                </ConfirmationDialog>
                            </div>
                            {user.role?.name?.toLowerCase() !== 'partner' && (
                                <>
                                    <div className="w-full flex mt-4">
                                        <TextFieldNoRoundedRight
                                            className="w-full"
                                            id="secondaryEmail"
                                            name="secondaryEmail"
                                            label={t('SECONDARY_EMAIL')}
                                            placeholder={user.secondaryEmail || ''}
                                            required
                                            type="email"
                                            size="small"
                                            value={secondaryEmail}
                                            onChange={e => setSecondaryEmail(e.target.value)}
                                            helperText={t('SECONDARY_EMAIL_HELPERTEXT')}
                                        />
                                        <LoadingButton
                                            className="rounded-l-none w-36"
                                            variant="outlined"
                                            color="primary"
                                            disabled={!secondaryEmail || secondaryEmail === user.secondaryEmail}
                                            onClick={() => setConfirmSecondaryEmailDialogOpen(true)}
                                            loading={!!loading?.secondaryEmail}
                                        >
                                            {t('CHANGE')}
                                        </LoadingButton>
                                        <ConfirmationDialog
                                            open={confirmSecondaryEmailDialogOpen}
                                            onClose={handleSecondaryEmailSave}
                                            title={t('CHANGE_EMAIL_TITLE')}
                                            okButton={t('CONFIRM')}
                                            cancelButton={t('CANCEL')}
                                            loading={!!loading?.email}
                                            disabled={secondaryEmail !== confirmEmail}
                                        >
                                            <DialogContentText>
                                                {t(
                                                    'TO_CHANGE_YOUR_EMAIL_ADDRESS_PLEASE_ENTER_YOUR_NEW_EMAIL_ADDRESS_BELOW_AND_CLICK_CONFIRM'
                                                )}
                                            </DialogContentText>
                                            <TextField
                                                autoFocus
                                                required
                                                margin="dense"
                                                id="name"
                                                name="confirmEmail"
                                                label={t('TYPE_YOUR_NEW_EMAIL_AGAIN')}
                                                fullWidth
                                                variant="standard"
                                                value={confirmEmail}
                                                onChange={e => setConfirmEmail(e.target.value)}
                                            />
                                        </ConfirmationDialog>
                                    </div>
                                    <div className="w-full flex mt-4">
                                        <AutocompleteNoRoundedRight
                                            options={educations || []}
                                            loading={educations === null}
                                            value={education}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setEducation(newValue);
                                                }
                                            }}
                                            getOptionLabel={option => option.name}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            className="w-full"
                                            size="small"
                                            renderInput={params => <TextField {...params} label={t('EDUCATION')} />}
                                            disableClearable
                                        />
                                        <LoadingButton
                                            className="rounded-l-none w-36 bg-white hover:bg-white"
                                            variant="outlined"
                                            color="primary"
                                            disabled={user.education?.id === education?.id}
                                            onClick={handleEducationSave}
                                            loading={!!loading?.education}
                                        >
                                            {t('CHANGE')}
                                        </LoadingButton>
                                    </div>
                                </>
                            )}
                            <div className="flex w-full mt-4">
                                <TextFieldNoRoundedRight
                                    className="w-full"
                                    id="password"
                                    name="password"
                                    label={t('PASSWORD')}
                                    placeholder="123456"
                                    required
                                    type="password"
                                    size="small"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                />
                                <TextFieldNoRounded
                                    className="w-full bg-white"
                                    id="passwordConfirm"
                                    name="passwordConfirm"
                                    label={t('CONFIRM_PASSWORD')}
                                    placeholder="123456"
                                    required
                                    type="password"
                                    size="small"
                                    value={passwordConfirmation}
                                    onChange={e => setPasswordConfirmation(e.target.value)}
                                />
                                <Button
                                    className="rounded-l-none w-36 bg-white hover:bg-white"
                                    variant="outlined"
                                    color="primary"
                                    disabled={!password || !passwordConfirmation || password !== passwordConfirmation}
                                    onClick={() => setConfirmPasswordDialogOpen(true)}
                                >
                                    {t('CHANGE')}
                                </Button>
                                <ConfirmationDialog
                                    open={confirmPasswordDialogOpen}
                                    onClose={handlePasswordSave}
                                    title={t('CONFIRM_PASSWORD_CHANGE')}
                                    okButton={t('CONFIRM')}
                                    cancelButton={t('CANCEL')}
                                    loading={!!loading?.password}
                                >
                                    <DialogContentText>
                                        {t('TO_CHANGE_YOUR_PASSWORD_YOU_MUST_ENTER_YOUR_CURRENT_PASSWORD')}
                                    </DialogContentText>
                                    <TextField
                                        autoFocus
                                        required
                                        margin="dense"
                                        id="name"
                                        name="password"
                                        label={t('PASSWORD')}
                                        type="password"
                                        fullWidth
                                        variant="standard"
                                        value={oldPassword}
                                        onChange={e => setOldPassword(e.target.value)}
                                    />
                                </ConfirmationDialog>
                            </div>
                            <div className="mt-4">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!!user?.newsletter_volunteer}
                                            onChange={e => {
                                                handleNewsletterToggle('newsletter_volunteer');
                                            }}
                                        />
                                    }
                                    label={t('PROFILE_NEWSLETTER_VOLUNTEER')}
                                />
                            </div>
                            <div className="">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!!user?.newsletter_events}
                                            onChange={e => {
                                                handleNewsletterToggle('newsletter_events');
                                            }}
                                        />
                                    }
                                    label={t('PROFILE_NEWSLETTER_EVENTS')}
                                />
                            </div>
                            <div className="">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!!user?.newsletter_benefits}
                                            onChange={e => {
                                                handleNewsletterToggle('newsletter_benefits');
                                            }}
                                        />
                                    }
                                    label={t('PROFILE_NEWSLETTER_BENEFITS')}
                                />
                            </div>
                            {/* <div className="mt-4"> */}
                            {/*    <Button */}
                            {/*        className="w-full" */}
                            {/*        variant="contained" */}
                            {/*        color="primary" */}
                            {/*        startIcon={<CloudUploadIcon />} */}
                            {/*        onClick={() => setSelectAvatarOpen(true)} */}
                            {/*        disabled={user.role?.name?.toLowerCase() !== 'member'} */}
                            {/*    > */}
                            {/*        {t('UPLOAD_PROFILE_PICTURE')} */}
                            {/*    </Button> */}
                            {/* </div> */}
                            {/* <ConfirmationDialog */}
                            {/*    open={selectAvatarOpen} */}
                            {/*    onClose={handleAvatarClose} */}
                            {/*    title={t('UPLOAD_PROFILE_PICTURE')} */}
                            {/*    okButton={t('UPLOAD')} */}
                            {/*    disabled={!selectedFile} */}
                            {/* > */}
                            {/*    <FileUploadButton */}
                            {/*        className="w-full" */}
                            {/*        onChange={event => { */}
                            {/*            setSelectedFile(event.target.files[0]); */}
                            {/*        }} */}
                            {/*    > */}
                            {/*        {t('SELECT_PROFILE_PICTURE')} */}
                            {/*    </FileUploadButton> */}
                            {/*    {selectedFile && ( */}
                            {/*        <div className="mt-4"> */}
                            {/*            <Typography> */}
                            {/*                {t('SELECTED_FILE')}: {selectedFile.name} */}
                            {/*            </Typography> */}
                            {/*        </div> */}
                            {/*    )} */}
                            {/* </ConfirmationDialog> */}
                        </CardContent>
                        <Icon
                            className="absolute bottom-0 right-0 -m-12 opacity-25 text-gray-600 z-1"
                            style={{ fontSize: '16rem' }}
                        >
                            person
                        </Icon>
                    </Card>
                    {user.role?.name?.toLowerCase() !== 'partner' && (
                        <Card className="p-6 mt-8 text-center relative">
                            <div>
                                <h2 className="text-xl font-bold">{t('EVENT_ATTENDANCE_CONFIRMATION')}</h2>
                            </div>
                            <CardContent className="relative cursor-pointer" onClick={handleToggleQR}>
                                <QRCodeSVG
                                    value={qrCode}
                                    className={clsx(
                                        'w-full h-full sm:w-60 sm:h-60',
                                        (!qrCodeEnabled || qrCodeLoading) && 'opacity-30'
                                    )}
                                />
                                {!qrCodeEnabled && (
                                    <div className="absolute w-full mx-auto" style={{ top: '8rem', right: 0, left: 0 }}>
                                        <Typography className="inline p-6 sm:p-20 text-black font-bold">
                                            {user.role?.name?.toLowerCase() === 'member'
                                                ? t('CLICK_TO_CONFIRM')
                                                : t('MEMBERSHIP_REQUIRED')}
                                        </Typography>
                                    </div>
                                )}
                                {qrCodeLoading && (
                                    <div className="absolute w-full mx-auto" style={{ top: '8rem', right: 0, left: 0 }}>
                                        <CircularProgress />
                                    </div>
                                )}
                                <p className="text-sm text-gray-500 mt-2">{t('SCAN_THIS_CODE_TO_CONFIRM')}</p>
                            </CardContent>
                            <Icon
                                className="absolute bottom-0 right-0 -m-12 opacity-25 text-gray-600"
                                style={{ fontSize: '16rem' }}
                            >
                                event
                            </Icon>
                        </Card>
                    )}
                </div>
            </div>
        </div>
    );
}
