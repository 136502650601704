import { NavLink, Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    AppBar,
    Avatar,
    Button,
    Divider,
    Drawer,
    Hidden,
    Icon,
    IconButton,
    InputAdornment,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    OutlinedInput,
    Popover,
    Typography
} from '@mui/material';
import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import clsx from 'clsx';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import ViewListIcon from '@mui/icons-material/ViewList';
import { selectNavigation } from '../store/shared/configSlice';
import { selectUser } from '../store/shared/authSlice';
import apiService from '../services/apiService';
import LanguageSwitcher from '../ui-components/LanguageSwitcher';

const NavButton = styled(Button)(({ theme }) => ({
    color: theme.palette.secondary.contrastText,
    '&.active': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    '&.child': {
        borderRadius: 0,
        minWidth: '8rem'
    },
    '& .MuiButton-endIcon': {
        marginLeft: '.15rem',
        marginTop: '-0.2rem'
    }
}));

export default function LayoutHeader({ hideNavigation }) {
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector(selectUser);
    const navigation = useSelector(selectNavigation);
    const [openChildren, setOpenChildren] = useState(null);
    const [userMenu, setUserMenu] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [searchMenu, setSearchMenu] = useState(false);
    const [searchText, setSearchText] = useState('');
    const { t } = useTranslation();

    const userMenuClick = event => {
        setUserMenu(event.currentTarget);
    };

    const userMenuClose = () => {
        setUserMenu(null);
    };

    function handleClickSearch() {
        setSearchMenu(oldState => !oldState);
    }

    function handleSearch() {
        if (searchText) {
            navigate(`/search?q=${searchText}`);
        }
        setSearchMenu(false);
        setSearchText('');
    }

    const mappedNavigation = useMemo(() => {
        if (!navigation) {
            return [];
        }
        const _navigation = _.cloneDeep(navigation.filter(item => !item?.attributes?.parent?.data?.id));
        _navigation.forEach(item => {
            item.children = navigation.filter(child => child?.attributes?.parent?.data?.id === item.id);
        });

        return _navigation;
    }, [navigation]);

    return (
        <AppBar
            className="w-full text-white py-6 shadow-md overflow-hidden"
            sx={{ backgroundColor: 'background.paper' }}
            style={{ height: '95px' }}
            position="fixed"
        >
            <div className="container mx-auto px-4 md:px-6 flex justify-between items-center">
                <div className="flex items-center">
                    <Hidden lgUp>
                        <IconButton className="mr-4" onClick={() => setDrawerOpen(true)}>
                            <Icon>menu</Icon>
                        </IconButton>
                    </Hidden>
                    <Link className="text-2xl font-bold tracking-tighter mr-10" to="/">
                        <img src="/images/sif-logo.svg" className="w-10" alt="logo" />
                    </Link>
                    <Hidden lgDown>
                        {mappedNavigation?.map(({ id, attributes: page, children }) => (
                            <Fragment key={`nav-${id}`}>
                                {children.length > 0 && (
                                    <NavButton
                                        onClick={e => setOpenChildren({ target: e.currentTarget, children })}
                                        endIcon={<Icon>arrow_drop_down</Icon>}
                                        className={
                                            children.some(child => child.attributes?.uri === location.pathname)
                                                ? 'active'
                                                : undefined
                                        }
                                    >
                                        {page.title}
                                    </NavButton>
                                )}
                                {children.length <= 0 && (
                                    <NavButton to={page.uri} component={NavLink}>
                                        {page.title}
                                    </NavButton>
                                )}
                            </Fragment>
                        ))}
                        <Menu
                            anchorEl={openChildren?.target}
                            open={!!openChildren}
                            onClose={() => setOpenChildren(null)}
                            className="w-64"
                            disableScrollLock
                        >
                            {openChildren?.children?.map(({ id, attributes: page }) => (
                                <NavButton
                                    key={`child-${id}`}
                                    to={page.uri}
                                    component={NavLink}
                                    onClick={() => setOpenChildren(null)}
                                    className="child whitespace-nowrap w-full justify-start"
                                >
                                    {page.title}
                                </NavButton>
                            ))}
                        </Menu>
                    </Hidden>
                </div>
                {!hideNavigation && (
                    <div className="flex items-center justify-between">
                        {searchMenu && (
                            <OutlinedInput
                                autoFocus
                                placeholder={t('SEARCH_PLACEHOLDER')}
                                size="small"
                                value={searchText}
                                onChange={e => setSearchText(e.target.value)}
                                onKeyPress={e => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton edge="end" onClick={handleSearch}>
                                            <Icon>search</Icon>
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        )}
                        {!searchMenu && <LanguageSwitcher />}
                        {!searchMenu && (
                            <IconButton onClick={handleClickSearch}>
                                <Icon>search</Icon>
                            </IconButton>
                        )}
                        {!user && (
                            <Button className="pl-0" to="/login" component={Link}>
                                <div className="hidden md:flex flex-col mx-4 items-end">
                                    <Typography
                                        component="span"
                                        className="font-semibold normal-case"
                                        color="textPrimary"
                                    >
                                        {t('LOGIN')}
                                    </Typography>
                                </div>

                                <Avatar />
                            </Button>
                        )}
                        {user && (
                            <>
                                <Button className="px-0 py-0" onClick={userMenuClick} color="inherit">
                                    <div className="hidden md:flex flex-col mx-4 items-end">
                                        <Typography
                                            component="span"
                                            className="font-semibold normal-case"
                                            color="textPrimary"
                                        >
                                            {user.firstname} {user.lastname}
                                        </Typography>
                                        <Typography className="text-xs font-medium normal-case" color="textSecondary">
                                            {user?.username?.toLowerCase() || ''}
                                        </Typography>
                                    </div>

                                    {user?.avatar?.url ? (
                                        <Avatar alt="user photo" src={apiService.getImage(user?.avatar?.url)} />
                                    ) : (
                                        <Avatar>{user?.firstname[0]}</Avatar>
                                    )}
                                </Button>
                                <Menu
                                    disableScrollLock
                                    open={Boolean(userMenu)}
                                    anchorEl={userMenu}
                                    onClose={userMenuClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}
                                    classes={{
                                        paper: 'py-2 min-w-40'
                                    }}
                                >
                                    <MenuItem component={Link} to="/min-profil" onClick={userMenuClose} role="button">
                                        <ListItemIcon className="min-w-40">
                                            <Icon>settings</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={t('YOUR_PROFILE')} />
                                    </MenuItem>
                                    {user?.education?.council?.slug && (
                                        <MenuItem
                                            component={Link}
                                            to={`/council/${user?.education?.council?.slug}`}
                                            onClick={userMenuClose}
                                            role="button"
                                        >
                                            <ListItemIcon className="min-w-40">
                                                <Icon>account_balance</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary={t('YOUR_COUNCIL')} />
                                        </MenuItem>
                                    )}
                                    {user.role?.name?.toLowerCase() !== 'partner' && (
                                        <MenuItem
                                            component={Link}
                                            to="/mine-klippekort"
                                            onClick={userMenuClose}
                                            role="button"
                                        >
                                            <ListItemIcon className="min-w-40">
                                                <ConfirmationNumberIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={t('YOUR_COUPONS')} />
                                        </MenuItem>
                                    )}
                                    {user.role?.name?.toLowerCase() === 'partner' && (
                                        <>
                                            <Divider />
                                            <MenuItem
                                                component={Link}
                                                to="/mine-opslag"
                                                onClick={userMenuClose}
                                                role="button"
                                            >
                                                <ListItemIcon className="min-w-40">
                                                    <ViewListIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Dine opslag" />
                                            </MenuItem>
                                        </>
                                    )}
                                    {user?.access_to_scan_members && (
                                        <>
                                            <Divider />
                                            <MenuItem
                                                component={Link}
                                                to="/event-admin"
                                                onClick={userMenuClose}
                                                role="button"
                                            >
                                                <ListItemIcon className="min-w-40">
                                                    <QrCodeScannerIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={t('SCAN_MEMBERS')} />
                                            </MenuItem>
                                        </>
                                    )}
                                    {user?.admin_for_councils?.length > 0 && (
                                        <>
                                            {!user?.access_to_scan_members && <Divider sx={{ my: 0.5 }} />}
                                            {user?.admin_for_councils?.map(council => (
                                                <MenuItem
                                                    key={`council-${council.slug}`}
                                                    component={Link}
                                                    to={`/council-admin/${council.slug}`}
                                                    onClick={userMenuClose}
                                                    role="button"
                                                >
                                                    <ListItemIcon className="min-w-40">
                                                        <Icon>shield</Icon>
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={`${t('ADMIN_FOR_COUNCIL')} ${
                                                            council.name || council.slug
                                                        }`}
                                                    />
                                                </MenuItem>
                                            ))}
                                        </>
                                    )}
                                    <Divider sx={{ my: 0.5 }} />
                                    <MenuItem component={Link} to="/logout" onClick={userMenuClose} role="button">
                                        <ListItemIcon className="min-w-40">
                                            <Icon color="error">exit_to_app</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={t('LOGOUT')} />
                                    </MenuItem>
                                </Menu>
                            </>
                        )}
                    </div>
                )}
                <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                    <div className="p-6 flex flex-col" style={{ minWidth: '10rem' }}>
                        {mappedNavigation?.map(({ id, attributes: page, children }) => (
                            <Fragment key={`drawer-nav-${id}`}>
                                {children.length > 0 && (
                                    <NavButton
                                        onClick={e => setOpenChildren({ target: e.currentTarget, children })}
                                        endIcon={<Icon>arrow_drop_down</Icon>}
                                        className={clsx(
                                            children.some(child => child.attributes?.uri === location.pathname)
                                                ? 'active'
                                                : undefined,
                                            'whitespace-nowrap w-full justify-start'
                                        )}
                                    >
                                        {page.title}
                                    </NavButton>
                                )}
                                {children.length <= 0 && (
                                    <NavButton
                                        to={page.uri}
                                        component={NavLink}
                                        onClick={() => setDrawerOpen(false)}
                                        className="whitespace-nowrap w-full justify-start"
                                    >
                                        {page.title}
                                    </NavButton>
                                )}
                            </Fragment>
                        ))}
                        <Menu
                            anchorEl={openChildren?.target}
                            open={!!openChildren}
                            onClose={() => setOpenChildren(null)}
                            className="w-64"
                        >
                            {openChildren?.children?.map(({ id, attributes: page }) => (
                                <NavButton
                                    key={`child-${id}`}
                                    to={page.uri}
                                    component={NavLink}
                                    onClick={() => {
                                        setOpenChildren(null);
                                        setDrawerOpen(false);
                                    }}
                                    className="child whitespace-nowrap w-full justify-start"
                                >
                                    {page.title}
                                </NavButton>
                            ))}
                        </Menu>
                    </div>
                </Drawer>
            </div>
        </AppBar>
    );
}
