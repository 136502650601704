import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { selectPageByUri } from '../store/shared/configSlice';
import apiService from '../services/apiService';
import ReportsComponent from './ReportsComponent';
import IframeComponent from './IframeComponent';
import EventsComponent from './EventsComponent';
import FAQComponent from './FAQComponent';
import MediasComponent from './MediasComponent';
import Redirect from '../ui-components/Redirect';
import NotFoundPage from './NotFoundPage';
import Loading from '../ui-components/Loading';
import HeroImageComponent from './HeroImageComponent';
import ContentComponent from './ContentComponent';
import FormComponent from './form/FormComponent';
import FormsPage from './form/FormsPage';
import PostsComponent from './post/PostsComponent';
import CouponShopComponent from './CouponShopComponent';

export default function ContentPage() {
    const location = useLocation();
    const page = useSelector(state => selectPageByUri(state, location.pathname));
    const [pageContent, setPageContent] = useState(null);

    useEffect(() => {
        if (page?.id) {
            apiService
                .get(
                    `/pages/${page.id}?` +
                        `populate[title]=*` +
                        `&${[
                            'active-forms',
                            'content',
                            'coupon-shop',
                            'events',
                            'faq',
                            'form',
                            'posts',
                            'redirect',
                            'reports',
                            'hero-image',
                            'iframe'
                        ]
                            .map(key => `populate[content][on][content.${key}][populate]=*`)
                            .join('&')}&populate[content][on][content.medias][populate][files][populate][0]=image`
                )
                .then(resp => {
                    setPageContent(resp.data.attributes);
                });
        }
    }, [page]);

    if (!page) {
        return <NotFoundPage />;
    }

    if (!pageContent) {
        return <Loading fullscreen />;
    }

    return (
        <div
            className="bg-[#E5E7EB]"
            style={{
                minHeight: 'calc(100vh - 95px)'
            }}
        >
            {pageContent.content.map((block, index) => (
                <>
                    {block.__component === 'content.content' && (
                        <ContentComponent
                            pageContent={block}
                            index={index}
                            last={pageContent.content.length === index + 1}
                        />
                    )}
                    {block.__component === 'content.faq' && (
                        <FAQComponent
                            index={index}
                            last={pageContent.content.length === index + 1}
                            pageContent={block}
                        />
                    )}
                    {block.__component === 'content.reports' && <ReportsComponent pageContent={block} />}
                    {block.__component === 'content.iframe' && <IframeComponent pageContent={block} />}
                    {block.__component === 'content.events' && <EventsComponent pageContent={block} />}
                    {block.__component === 'content.medias' && <MediasComponent pageContent={block} />}
                    {block.__component === 'content.redirect' && <Redirect to={block.redirectUrl} />}
                    {block.__component === 'content.hero-image' && <HeroImageComponent pageContent={block} />}
                    {block.__component === 'content.form' && <FormComponent pageContent={block} />}
                    {block.__component === 'content.active-forms' && <FormsPage pageContent={block} />}
                    {block.__component === 'content.posts' && <PostsComponent pageContent={block} />}
                    {block.__component === 'content.coupon-shop' && <CouponShopComponent pageContent={block} />}
                </>
            ))}
        </div>
    );
}
