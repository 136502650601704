import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export default function Redirect({ to }) {
    const navigate = useNavigate();
    useEffect(() => {
        if (to.startsWith('http')) {
            window.location.replace(to);
        } else {
            navigate(to);
        }
    }, [navigate, to]);

    return null;
}
