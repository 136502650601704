const allLanguages = Object.freeze({
    da: {
        isoCode: 'da',
        title: 'Danish',
        flag: 'DK',
        translationKey: 'danish'
    },
    en: {
        isoCode: 'en',
        title: 'English',
        flag: 'GB',
        translationKey: 'english'
    }
});

export default allLanguages;
