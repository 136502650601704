import { useEffect, useMemo, useState } from 'react';
import {
    Alert,
    Button,
    Card,
    CardActions,
    CardContent,
    Icon,
    Link as MuiLink,
    TextField,
    Typography
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LayoutHeader from '../../layout/LayoutHeader';
import TextFieldForm from '../../ui-components/TextFieldForm';
import {
    login,
    loginWithLocal,
    resendConfirmation,
    selectAuthError,
    selectRegisterSuccess,
    selectUser,
    setAuthError
} from '../../store/shared/authSlice';

export default function PartnerLoginPage() {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const authError = useSelector(selectAuthError);
    const registerSuccess = useSelector(selectRegisterSuccess);
    const navigate = useNavigate();
    const { handleSubmit, control } = useForm({
        mode: 'onChange',
        defaultValues: {
            email: '',
            password: ''
        }
    });
    const { t } = useTranslation();
    const [useLocalAuth, setUseLocalAuth] = useState(true);

    useEffect(() => {
        dispatch(setAuthError(null));
    }, [dispatch]);

    const emailConfirmed = useMemo(() => {
        const query = new URLSearchParams(window.location.search);
        return query.get('confirmed') !== null && query.get('confirmed') !== undefined;
    }, []);

    const emailNotConfirmed = useMemo(() => {
        return authError?.message === 'NOT_CONFIRMED';
    }, [authError]);

    useEffect(() => {
        if (user) {
            navigate('/min-profil');
        }
    }, [navigate, user]);

    function onSubmit(data) {
        if (emailNotConfirmed) {
            dispatch(resendConfirmation(data.email));
        } else if (useLocalAuth) {
            dispatch(loginWithLocal(data.email, data.password));
        } else {
            dispatch(login(data.email, data.password));
        }
    }

    return (
        <div className="h-screen bg-[#E5E7EB]">
            <LayoutHeader />
            <div
                style={{
                    paddingTop: '95px'
                }}
            >
                <Card className="mx-auto my-10 sm:my-20 p-2 sm:p-8 w-full sm:w-96 rounded-lg shadow-lg">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <CardContent className="space-y-4">
                            <Typography variant="h6" className="text-2xl">
                                {useLocalAuth ? 'Partner ' : ''}
                                {t('LOGIN')}
                            </Typography>
                            <Typography>{t('LOGIN_TO_YOUR_ACCOUNT')}</Typography>
                            {authError && ['INVALID_PASSWORD', 'NOT_CONFIRMED'].includes(authError?.message) && (
                                <Alert severity="error" className="-mx-6 sm:-mx-12 -mt-4">
                                    {t(`AUTH_ERROR_${authError?.message}`)}
                                </Alert>
                            )}
                            {authError && !['INVALID_PASSWORD', 'NOT_CONFIRMED'].includes(authError?.message) && (
                                <Alert severity="error" className="-mx-6 sm:-mx-12 -mt-4">
                                    {authError.message}
                                </Alert>
                            )}
                            {emailConfirmed && (
                                <Alert severity="info" className="-mx-6 sm:-mx-12 -mt-4">
                                    {t('YOUR_EMAIL_ARE_NOW_CONFIRMED')}
                                </Alert>
                            )}

                            {registerSuccess?.email && (
                                <Alert severity="success" className="-mx-6 sm:-mx-12 -mt-4">
                                    {t('CONFIRMATION_EMAIL_SENT_TO')} {registerSuccess?.email}
                                    <br />
                                    {t('CLICK_THE_LINK_TO_CONFIRM')}
                                </Alert>
                            )}
                            <div className="">
                                <label className="block text-sm font-medium text-gray-700" htmlFor="email">
                                    {t('EMAIL')}
                                </label>
                                <TextFieldForm
                                    control={control}
                                    autoComplete="email"
                                    size="small"
                                    placeholder={t('YOUR_EMAIL')}
                                    className="w-full"
                                    id="email"
                                    name="email"
                                    required
                                    type="email"
                                />
                            </div>
                            {!emailNotConfirmed && (
                                <div className="">
                                    <label className="block text-sm font-medium text-gray-700" htmlFor="password">
                                        {t('PASSWORD')}
                                    </label>
                                    <TextFieldForm
                                        control={control}
                                        autoComplete="current-password"
                                        size="small"
                                        className="w-full"
                                        placeholder={t('YOUR_PASSWORD')}
                                        id="password"
                                        name="password"
                                        required
                                        type="password"
                                    />
                                </div>
                            )}
                            <Button className="w-full py-2 px-4" variant="contained" color="primary" type="submit">
                                {emailNotConfirmed ? t('RESEND_CONFIRMATION') : t('LOGIN')}
                            </Button>
                            <div className="flex justify-end w-full">
                                <MuiLink
                                    className="text-blue-500 no-underline hover:underline text-xs -mt-2"
                                    to="/forgot-password"
                                    component={Link}
                                >
                                    {t('LOGIN_FORGOT_PASSWORD')}
                                </MuiLink>
                            </div>
                            {/* <div className="flex items-center space-x-2"> */}
                            {/*    <Button */}
                            {/*        className="w-full py-2 px-4" */}
                            {/*        variant="outlined" */}
                            {/*        color="primary" */}
                            {/*        startIcon={<Icon className="w-4 h-4">computer</Icon>} */}
                            {/*        onClick={() => setUseLocalAuth(oldState => !oldState)} */}
                            {/*    > */}
                            {/*        {useLocalAuth ? 'Log ind som SDU bruger' : 'Log ind som partner'} */}
                            {/*    </Button> */}
                            {/* </div> */}
                        </CardContent>
                    </form>
                    {/* <CardActions> */}
                    {/*    <div className="flex justify-center flex-col items-center w-full text-gray-600"> */}
                    {/*        {!emailNotConfirmed && ( */}
                    {/*            <> */}
                    {/*                <Typography>{t('DONT_HAVE_AN_ACCOUNT')}</Typography> */}
                    {/*                <MuiLink className="text-blue-500 hover:underline" to="/register" component={Link}> */}
                    {/*                    {t('CREATE_AN_ACCOUNT')} */}
                    {/*                </MuiLink> */}
                    {/*            </> */}
                    {/*        )} */}
                    {/*        {emailNotConfirmed && ( */}
                    {/*            <> */}
                    {/*                <Typography>{t('WOULD_YOU_TRY_TO_LOGIN_AGAIN')}</Typography> */}
                    {/*                <Button */}
                    {/*                    className="text-blue-500 hover:underline" */}
                    {/*                    variant="link" */}
                    {/*                    onClick={() => { */}
                    {/*                        dispatch(setAuthError(null)); */}
                    {/*                    }} */}
                    {/*                > */}
                    {/*                    {t('LOGIN')} */}
                    {/*                </Button> */}
                    {/*            </> */}
                    {/*        )} */}
                    {/*    </div> */}
                    {/* </CardActions> */}
                </Card>
            </div>
        </div>
    );
}
