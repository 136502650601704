import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Alert,
    ButtonGroup,
    Card,
    CardContent,
    DialogContentText,
    Grid,
    List,
    ListItem,
    ListItemText,
    styled,
    TableCell,
    TextField,
    Typography,
    Table
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import { selectUser } from '../../store/shared/authSlice';
import apiService from '../../services/apiService';
import { showMessage } from '../../store/shared/messageSlice';
import ConfirmationDialog from '../../ui-components/ConfirmationDialog';
import CustomCKEditor from '../../ui-components/CKEditor';

const TextFieldNoRoundedRight = styled(TextField)({
    '& .MuiInputBase-root': {
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px'
    }
});

export default function CouncilAdminPage() {
    const { slug } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [council, setCouncil] = useState(null);
    const [room, setRoom] = useState('');
    const [email, setEmail] = useState('');
    const [admins, setAdmins] = useState([]);
    const [content, setContent] = useState('');
    const authUser = useSelector(selectUser);
    const [loading, setLoading] = useState({});
    const [confirmAddAdmin, setConfirmAddAdmin] = useState(null);
    const [confirmDeleteAdmin, setConfirmDeleteAdmin] = useState(null);
    const [confirmEmail, setConfirmEmail] = useState(null);
    const { t } = useTranslation();

    const hasAccess = useMemo(() => {
        return authUser && authUser.admin_for_councils.some(_council => _council.slug === slug);
    }, [authUser, slug]);

    useEffect(() => {
        if (!hasAccess) {
            navigate('/min-profil');
        }
    }, [hasAccess, navigate]);

    const requestCouncil = useCallback(() => {
        apiService
            .get(
                `/councils?filters[slug]=${slug}&populate[0]=educations&populate[1]=admins&populate[2]=name&populate[3]=educations.name`
            )
            .then(response => {
                if (response.data?.[0] && response.data?.[0]?.name) {
                    return response.data?.[0];
                }
                return apiService
                    .get(
                        `/councils?filters[slug]=${slug}&populate[0]=educations&populate[1]=admins&populate[2]=name&populate[3]=educations.name`,
                        {
                            params: {
                                locale: null
                            }
                        }
                    )
                    .then(response2 => {
                        return response2.data?.[0];
                    });
            })
            .then(resp => {
                setCouncil(resp);
            });
    }, [slug]);

    useEffect(() => {
        setRoom(council?.attributes?.room || '');
        setEmail(council?.attributes?.email || '');
        setAdmins(council?.attributes?.admins?.data || []);
        setContent(council?.attributes?.content || '');
    }, [council]);

    useEffect(() => {
        if (!hasAccess) {
            return;
        }
        requestCouncil();
    }, [hasAccess, requestCouncil]);

    function handleEmailSave() {
        setLoading(oldState => ({ ...oldState, email: true }));
        apiService
            .put(`/councils/${council.id}`, {
                data: {
                    email
                }
            })
            .then(() => {
                requestCouncil();
                dispatch(showMessage({ message: t('COUNCIL_EMAIL_CHANGED_SUCCESS'), variant: 'success' }));
            })
            .catch(e => {
                dispatch(
                    showMessage({
                        message: e.response?.data?.error?.message || t('UNKNOWN_ERROR'),
                        variant: 'error'
                    })
                );
            })
            .finally(() => setLoading(oldState => ({ ...oldState, email: false })));
    }

    function handleRoomSave() {
        setLoading(oldState => ({ ...oldState, room: true }));
        apiService
            .put(`/councils/${council.id}`, {
                data: {
                    room
                }
            })
            .then(() => {
                requestCouncil();
                dispatch(showMessage({ message: t('COUNCIL_ROOM_CHANGED_SUCCESS'), variant: 'success' }));
            })
            .catch(e => {
                dispatch(
                    showMessage({
                        message: e.response?.data?.error?.message || t('UNKNOWN_ERROR'),
                        variant: 'error'
                    })
                );
            })
            .finally(() => setLoading(oldState => ({ ...oldState, room: false })));
    }

    function handleContentSave() {
        setLoading(oldState => ({ ...oldState, content: true }));
        apiService
            .put(`/councils/${council.id}`, {
                data: {
                    content
                }
            })
            .then(() => {
                requestCouncil();
                dispatch(
                    showMessage({
                        message: t('COUNCIL_CONTENT_CHANGED_SUCCESS'),
                        variant: 'success'
                    })
                );
            })
            .catch(e => {
                dispatch(
                    showMessage({
                        message: e.response?.data?.error?.message || t('UNKNOWN_ERROR'),
                        variant: 'error'
                    })
                );
            })
            .finally(() => setLoading(oldState => ({ ...oldState, content: false })));
    }

    function handleContentReset() {
        setContent(council?.attributes?.content || '');
    }

    function handleRemoveAdminDialog(admin) {
        setConfirmDeleteAdmin(admin);
    }

    function handleRemoveAdmin(result) {
        if (!result) {
            setConfirmDeleteAdmin(null);
            setConfirmEmail('');
            return;
        }
        setLoading(oldState => ({ ...oldState, removeAdmin: true }));
        apiService
            .put(`/councils/${council.id}`, {
                data: {
                    admins: {
                        disconnect: [confirmDeleteAdmin.id]
                    }
                }
            })
            .then(() => {
                requestCouncil();
                dispatch(
                    showMessage({
                        message: t('COUNCIL_ADMIN_REMOVED_SUCCESS'),
                        variant: 'success'
                    })
                );
                setConfirmDeleteAdmin(null);
            })
            .catch(e => {
                dispatch(
                    showMessage({
                        message: e.response?.data?.error?.message || t('UNKNOWN_ERROR'),
                        variant: 'error'
                    })
                );
            })
            .finally(() => {
                setLoading(oldState => ({ ...oldState, removeAdmin: false }));
                setConfirmEmail('');
            });
    }

    function handleAddAdminDialog() {
        setConfirmAddAdmin(true);
    }

    function handleAddAdmin(result) {
        if (!result) {
            setConfirmAddAdmin(false);
            setConfirmEmail('');
            return;
        }
        setLoading(oldState => ({ ...oldState, addAdmin: true }));
        apiService
            .put(`/councils/${council.id}`, {
                data: {
                    admins: {
                        connect: [confirmEmail]
                    }
                }
            })
            .then(() => {
                requestCouncil();
                dispatch(
                    showMessage({
                        message: t('COUNCIL_ADMIN_ADDED_SUCCESS'),
                        variant: 'success'
                    })
                );
                setConfirmAddAdmin(false);
            })
            .catch(e => {
                dispatch(
                    showMessage({
                        message: e.response?.data?.error?.message || t('UNKNOWN_ERROR'),
                        variant: 'error'
                    })
                );
            })
            .finally(() => {
                setLoading(oldState => ({ ...oldState, addAdmin: false }));
                setConfirmEmail('');
            });
    }

    return (
        <div className="w-full min-h-screen bg-gray-200 pt-10 p-2 md:p-10">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <h2 className="text-2xl text-center font-bold py-0 -mt-2 mb-2">
                        {t('ADMIN_FOR_COUNCIL')} {council?.attributes?.name}
                    </h2>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card className="h-full">
                        <div className="px-6">
                            <h2 className="text-xl font-bold">{t('COUNCIL_INFORMATION')}</h2>
                        </div>
                        <CardContent className="px-6">
                            <div className="flex w-full mt-4">
                                <TextFieldNoRoundedRight
                                    className="w-full"
                                    id="email"
                                    name="email"
                                    type="email"
                                    label={t('EMAIL')}
                                    placeholder="email@email.dk"
                                    required
                                    size="small"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                                <LoadingButton
                                    className="rounded-l-none w-36 bg-white hover:bg-white"
                                    variant="outlined"
                                    color="primary"
                                    disabled={!email || email === council?.attributes?.email}
                                    onClick={handleEmailSave}
                                    loading={!!loading.email}
                                >
                                    {t('CHANGE')}
                                </LoadingButton>
                            </div>
                            <div className="flex w-full mt-4">
                                <TextFieldNoRoundedRight
                                    className="w-full"
                                    id="room"
                                    name="room"
                                    label={t('COUNCIL_ROOM')}
                                    placeholder={t('COUNCIL_ROOM')}
                                    size="small"
                                    value={room}
                                    onChange={e => setRoom(e.target.value)}
                                />
                                <LoadingButton
                                    className="rounded-l-none w-36 bg-white hover:bg-white"
                                    variant="outlined"
                                    color="primary"
                                    disabled={room === (council?.attributes?.room || '')}
                                    onClick={handleRoomSave}
                                    loading={!!loading.room}
                                >
                                    {t('CHANGE')}
                                </LoadingButton>
                            </div>
                            <div>
                                <Typography className="text-gray-700 mt-6">
                                    {t('COUNCIL_INFORMATION_IS_VISIBLE_TO_STUDENTS')}
                                </Typography>
                                <List dense>
                                    {council?.attributes?.educations?.data.map(education => (
                                        <ListItem>
                                            <ListItemText primary={education.attributes.name} />
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card className="h-full">
                        <div className="px-6 flex justify-between items-center">
                            <h2 className="text-xl font-bold">{t('COUNCIL_ADMINS')}</h2>
                            <Button variant="contained" size="small" color="primary" onClick={handleAddAdminDialog}>
                                {t('COUNCIL_ADD_ADMIN')}
                            </Button>
                        </div>
                        <CardContent className="px-6 overflow-x-auto">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('COUNCIL_ADMIN_NAME')}</TableCell>
                                        <TableCell>{t('COUNCIL_ADMIN_EMAIL')}</TableCell>
                                        <TableCell>{t('COUNCIL_ADMIN_ACTIONS')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {admins.map(admin => (
                                        <TableRow>
                                            <TableCell>
                                                {admin.attributes.firstname} {admin.attributes.lastname}
                                            </TableCell>
                                            <TableCell>{admin.attributes.email}</TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    size="small"
                                                    onClick={() => handleRemoveAdminDialog(admin)}
                                                >
                                                    {t('COUNCIL_REMOVE_ADMIN')}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <ConfirmationDialog
                                open={!!confirmAddAdmin}
                                onClose={handleAddAdmin}
                                title={t('COUNCIL_ADD_ADMIN')}
                                okButton={t('CONFIRM')}
                                cancelButton={t('CANCEL')}
                                loading={!!loading?.addAdmin}
                                // fullScreen
                            >
                                <DialogContentText>
                                    {t('COUNCIL_ENTER_EMAIL_FOR_NEW_ADMIN')}{' '}
                                    <span className="font-bold">{council?.attributes?.name}</span>?
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    required
                                    margin="dense"
                                    id="newEmail"
                                    name="newEmail"
                                    label={t('EMAIL')}
                                    fullWidth
                                    variant="standard"
                                    value={confirmEmail}
                                    onChange={e => setConfirmEmail(e.target.value)}
                                />
                            </ConfirmationDialog>
                            <ConfirmationDialog
                                open={!!confirmDeleteAdmin}
                                onClose={handleRemoveAdmin}
                                title={t('COUNCIL_REMOVE_ADMIN')}
                                okButton={t('CONFIRM')}
                                cancelButton={t('CANCEL')}
                                loading={!!loading?.removeAdmin}
                                disabled={authUser?.id === confirmDeleteAdmin?.id && email !== confirmEmail}
                                // fullScreen
                            >
                                {authUser?.id === confirmDeleteAdmin?.id && (
                                    <Alert severity="error" className="mb-4">
                                        {t('COUNCIL_THIS_ACTION_IS_IRREVERSIBLE')}{' '}
                                        <span className="font-bold">{t('COUNCIL_REMOVE_YOU_AS_ADMIN')}</span>
                                    </Alert>
                                )}
                                <DialogContentText>
                                    {t('COUNCIL_ARE_YOU_SURE_YOU_WANT_TO_REMOVE')}{' '}
                                    <span className="font-bold">
                                        {confirmDeleteAdmin?.attributes?.firstname}{' '}
                                        {confirmDeleteAdmin?.attributes?.lastname}
                                    </span>{' '}
                                    {t('COUNCIL_AS_AN_ADMIN')}{' '}
                                    <span className="font-bold">{council?.attributes?.name}</span>?
                                </DialogContentText>
                                {authUser?.id === confirmDeleteAdmin?.id && (
                                    <TextField
                                        autoFocus
                                        required
                                        margin="dense"
                                        id="confirmEmail"
                                        name="confirmEmail"
                                        label={t('COUNCIL_ENTER_YOUR_EMAIL_TO_CONFIRM')}
                                        fullWidth
                                        variant="standard"
                                        value={confirmEmail}
                                        onChange={e => setConfirmEmail(e.target.value)}
                                    />
                                )}
                            </ConfirmationDialog>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <div className="px-6 flex justify-between items-center">
                            <h2 className="text-xl font-bold">{t('COUNCIL_CONTENT')}</h2>
                            <ButtonGroup>
                                <Button
                                    className="mt-4"
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleContentReset}
                                    disabled={content === council?.attributes?.content}
                                >
                                    {t('COUNCIL_RESET_CONTENT')}
                                </Button>
                                <Button
                                    className="mt-4"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleContentSave}
                                    disabled={content === council?.attributes?.content}
                                >
                                    {t('COUNCIL_RESET_SAVE')}
                                </Button>
                            </ButtonGroup>
                        </div>
                        <CardContent className="px-6">
                            <CustomCKEditor
                                data={content}
                                onChange={(event, editor) => {
                                    setContent(editor.getData());
                                }}
                            />
                            <div className="flex justify-end">
                                <ButtonGroup>
                                    <Button
                                        className="mt-4"
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleContentReset}
                                        disabled={content === council?.attributes?.content}
                                    >
                                        {t('COUNCIL_RESET_CONTENT')}
                                    </Button>
                                    <Button
                                        className="mt-4"
                                        variant="contained"
                                        color="primary"
                                        onClick={handleContentSave}
                                        disabled={content === council?.attributes?.content}
                                    >
                                        {t('COUNCIL_RESET_SAVE')}
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
