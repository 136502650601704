import { motion } from 'framer-motion';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Icon, Input, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';

const Root = styled('div')(({ theme }) => ({
    '& .FaqPage-header': {
        background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
        color: theme.palette.primary.contrastText
    },

    '& .FaqPage-panel': {
        margin: 0,
        border: 'none',
        '&:before': {
            display: 'none'
        },
        '&:first-of-type': {
            borderRadius: '15px 15px 0 0'
        },
        '&:last-of-type': {
            borderRadius: '0 0 15px 15px'
        },
        '&:first-of-type:last-of-type': {
            borderRadius: '15px !important'
        },

        '&.Mui-expanded': {
            // margin: 'auto',
        }
    }
}));

export default function FAQComponent({ last, index, pageContent }) {
    const [expanded, setExpanded] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [viewableHeight, setViewableHeight] = useState(0);
    const { t, i18n } = useTranslation();

    useLayoutEffect(() => {
        let _viewableHeight = 0;
        document.querySelectorAll('.content-component').forEach(doc => {
            if (!doc.classList.contains(`content-component-${index}`)) {
                _viewableHeight += doc.offsetHeight;
            }
        });
        setViewableHeight(_viewableHeight);
    }, [index]);

    useEffect(() => {
        const resizeFunc = () => {
            let _viewableHeight = 0;
            document.querySelectorAll('.content-component').forEach(doc => {
                if (!doc.classList.contains(`content-component-${index}`)) {
                    _viewableHeight += doc.offsetHeight;
                }
            });
            setViewableHeight(_viewableHeight);
        };
        window.addEventListener('resize', resizeFunc);
        return () => {
            window.removeEventListener('resize', resizeFunc);
        };
    }, [index]);

    const items = useMemo(() => {
        const faqItems = pageContent?.faq || [];
        return faqItems.filter(item => {
            if (!item[`question_${i18n.language}`] || !item[`answer_${i18n.language}`]) {
                return false;
            }
            return (
                item[`question_${i18n.language}`].toLowerCase().includes(searchText.toLowerCase()) ||
                item[`answer_${i18n.language}`].toLowerCase().includes(searchText.toLowerCase())
            );
        });
    }, [pageContent?.faq, searchText, i18n.language]);

    function toggleAccordion(id) {
        setExpanded(expanded === id ? null : id);
    }

    function handleSearch(event) {
        setSearchText(event.target.value);
    }

    return (
        <Root
            style={{
                backgroundColor: pageContent?.colors?.backgroundColor || undefined,
                color: pageContent?.colors?.textColor || undefined,
                paddingBottom: !last ? '3rem' : undefined,
                minHeight: last ? `${window.innerHeight - 95 - viewableHeight}px` : undefined
            }}
            className={`content-component content-component-${index}`}
        >
            {pageContent.header && (
                <div className="FaqPage-header flex flex-col shrink-0 items-center justify-center text-center p-10 pb-32">
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.3 } }}>
                        <Typography
                            variant="subtitle1"
                            color="inherit"
                            className="opacity-75 mt-8 sm:mt-16 mx-auto max-w-512 font-medium"
                        >
                            {t('FAQ_SUBTITLE')}
                        </Typography>
                    </motion.div>

                    <motion.div
                        initial={{ opacity: 0, y: 40 }}
                        animate={{ opacity: 1, y: 0, transition: { delay: 0.1 } }}
                    >
                        <Typography color="inherit" className="text-lg sm:text-5xl font-bold tracking-tight mt-2">
                            {t('FAQ_TITLE')}
                        </Typography>
                    </motion.div>

                    <Paper className="flex shrink-0 items-center h-12 w-full max-w-md mt-16 rounded-2xl shadow">
                        <Icon color="action" className="mx-3">
                            search
                        </Icon>
                        <Input
                            placeholder={t('FAQ_SEARCH_PLACEHOLDER')}
                            className=""
                            disableUnderline
                            fullWidth
                            inputProps={{
                                'aria-label': 'Search'
                            }}
                            value={searchText}
                            onChange={handleSearch}
                        />
                    </Paper>
                </div>
            )}
            <div className="flex flex-col flex-1 shrink-0 max-w-3xl w-full mx-auto py-16 px-4">
                {items.length === 0 && (
                    <div className="flex flex-auto items-center justify-center w-full h-full">
                        <Typography color="textSecondary" variant="h5">
                            {t('FAQ_NO_QUESTIONS')}
                        </Typography>
                    </div>
                )}
                {items.map(item => (
                    <Accordion
                        component={motion.div}
                        variants={item}
                        key={item.id}
                        classes={{
                            root: 'FaqPage-panel shadow-0'
                        }}
                        expanded={expanded === item.id}
                        onChange={() => toggleAccordion(item.id)}
                    >
                        <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                            <div className="flex items-center py-2">
                                <Icon color="action">help_outline</Icon>
                                <Typography className="px-4 font-medium">
                                    <div
                                        dangerouslySetInnerHTML={{ __html: item[`question_${i18n.language}`] }}
                                        className="ck-content faq"
                                    />
                                </Typography>
                            </div>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography className="text-14 px-10 pb-2 -mt-6">
                                <div
                                    dangerouslySetInnerHTML={{ __html: item[`answer_${i18n.language}`] }}
                                    className="ck-content"
                                />
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </Root>
    );
}
