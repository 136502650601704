import { Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import apiService from '../services/apiService';
import Loading from '../ui-components/Loading';

export default function EventPage() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [event, setEvent] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        apiService
            .get(`/events/${id}?populate=image`)
            .then(response => {
                setEvent(response.data);
            })
            .catch(e => {
                if (e.response.status === 404) {
                    navigate('/404');
                }
            });
    }, [id, navigate]);

    return (
        <div className="w-full h-full bg-[#E5E7EB]">
            {!event && <Loading fullWidth />}
            {event && (
                <>
                    <section className={clsx('w-full py-12 bg-gray-900 text-white hero-image', `hero-image-${id}`)}>
                        <style>
                            {`
                                .hero-image-${id}:before {
                                    background-image: url(${apiService.getImage(
                                        event?.attributes?.image?.data?.attributes?.url
                                    )});
                                }
                            `}
                        </style>
                        <div className="container px-4 md:px-6 mx-auto hero-content pb-0">
                            <div className="flex flex-col justify-center space-y-4">
                                <h1 className="text-3xl font-bold tracking-tighter sm:text-5xl xl:text-6xl">
                                    {event.attributes.name}
                                </h1>
                            </div>
                            <Typography variant="h6" className="absolute right-0 -bottom-10">
                                {moment(event.attributes.start).format('DD.MM.YYYY HH:mm')}
                            </Typography>
                        </div>
                    </section>
                    <section
                        className="container mx-auto py-12 md:py-24 lg:py-12 px-6 md:px-0"
                        style={{
                            minHeight: 'calc(100vh - 95px)'
                        }}
                    >
                        {!event.attributes.description && (
                            <Typography variant="body1" className="mt-4">
                                {t('NO_DESCRIPTION')}
                            </Typography>
                        )}
                        {event.attributes.description && (
                            <Typography variant="body1" className="mt-4">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: event.attributes.description
                                    }}
                                    className="ck-content"
                                />
                            </Typography>
                        )}
                    </section>
                </>
            )}
        </div>
    );
}
