import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { Provider } from 'react-redux';
import moment from 'moment';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import Auth from './store/Auth';
import ConfigInitializer from './store/ConfigInitializer';
import './i18n';
import 'moment/locale/da';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

moment.locale(localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language'))?.isoCode : 'da');

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#2A368F'
        },
        secondary: {
            main: '#FFFFFF'
        }
    },
    components: {
        MuiPopover: {
            defaultProps: {
                container: rootElement
            }
        },
        MuiPopper: {
            defaultProps: {
                container: rootElement
            }
        }
    }
});

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <Auth>
                <ConfigInitializer>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <App />
                        </ThemeProvider>
                    </StyledEngineProvider>
                </ConfigInitializer>
            </Auth>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
