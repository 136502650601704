import { CKEditor } from '@ckeditor/ckeditor5-react';

import {
    ClassicEditor,
    AccessibilityHelp,
    Alignment,
    AutoImage,
    AutoLink,
    Autosave,
    Bold,
    Essentials,
    Heading,
    HorizontalLine,
    ImageBlock,
    ImageResize,
    ImageInline,
    ImageInsertViaUrl,
    ImageToolbar,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    ListProperties,
    Paragraph,
    SelectAll,
    Strikethrough,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TodoList,
    Underline,
    Undo,
    WordCount
} from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';
import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';

export default function CustomCKEditor(props) {
    const [stats, setStats] = useState({});

    const editorConfig = {
        toolbar: {
            items: props.basic
                ? [
                      'undo',
                      'redo',
                      '|',
                      'selectAll',
                      '|',
                      'bold',
                      'italic',
                      'underline',
                      'strikethrough',
                      '|',
                      'accessibilityHelp'
                  ]
                : [
                      'undo',
                      'redo',
                      '|',
                      'selectAll',
                      '|',
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'underline',
                      'strikethrough',
                      '|',
                      'horizontalLine',
                      'link',
                      'insertImageViaUrl',
                      'insertTable',
                      '|',
                      'alignment',
                      '|',
                      'bulletedList',
                      'numberedList',
                      'todoList',
                      'indent',
                      'outdent',
                      '|',
                      'accessibilityHelp'
                  ],
            shouldNotGroupWhenFull: false
        },
        plugins: [
            AccessibilityHelp,
            Alignment,
            AutoImage,
            AutoLink,
            Autosave,
            Bold,
            Essentials,
            Heading,
            HorizontalLine,
            ImageBlock,
            ImageResize,
            ImageInline,
            ImageInsertViaUrl,
            ImageToolbar,
            Indent,
            IndentBlock,
            Italic,
            Link,
            List,
            ListProperties,
            Paragraph,
            SelectAll,
            Strikethrough,
            Table,
            TableCaption,
            TableCellProperties,
            TableColumnResize,
            TableProperties,
            TableToolbar,
            TodoList,
            Underline,
            Undo,
            WordCount
        ],
        heading: {
            options: [
                {
                    model: 'paragraph',
                    title: 'Paragraph',
                    class: 'ck-heading_paragraph'
                },
                {
                    model: 'heading1',
                    view: 'h1',
                    title: 'Heading 1',
                    class: 'ck-heading_heading1'
                },
                {
                    model: 'heading2',
                    view: 'h2',
                    title: 'Heading 2',
                    class: 'ck-heading_heading2'
                },
                {
                    model: 'heading3',
                    view: 'h3',
                    title: 'Heading 3',
                    class: 'ck-heading_heading3'
                },
                {
                    model: 'heading4',
                    view: 'h4',
                    title: 'Heading 4',
                    class: 'ck-heading_heading4'
                },
                {
                    model: 'heading5',
                    view: 'h5',
                    title: 'Heading 5',
                    class: 'ck-heading_heading5'
                },
                {
                    model: 'heading6',
                    view: 'h6',
                    title: 'Heading 6',
                    class: 'ck-heading_heading6'
                }
            ]
        },
        image: {
            toolbar: ['imageTextAlternative', '|', 'resizeImage']
        },
        language: 'da',
        link: {
            addTargetToExternalLinks: true,
            defaultProtocol: 'https://',
            decorators: {
                toggleDownloadable: {
                    mode: 'manual',
                    label: 'Downloadable',
                    attributes: {
                        download: 'file'
                    }
                }
            }
        },
        list: {
            properties: {
                styles: true,
                startIndex: true,
                reversed: true
            }
        },
        table: {
            contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
        },
        wordCount: {
            onUpdate: data => {
                setStats(data);
            }
        }
    };

    useEffect(() => {
        if (props.maxCharacters > 0) {
            props.onMax(stats?.characters > props.maxCharacters);
        }
    }, [props, stats]);

    return (
        <>
            <CKEditor editor={ClassicEditor} config={editorConfig} {...props} />
            {props.maxCharacters > 0 && (
                <div className="flex justify-end mt-1 mr-1">
                    <Typography variant="caption" color={stats?.characters > props.maxCharacters ? 'error' : undefined}>
                        {stats?.characters} / {props.maxCharacters}
                    </Typography>
                </div>
            )}
        </>
    );
}
