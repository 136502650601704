import { CircularProgress, Typography } from '@mui/material';

export default function Loading({ fullscreen, fullWidth, style, text }) {
    if (fullscreen) {
        return (
            <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-90 z-50 flex justify-center items-center">
                <CircularProgress />
            </div>
        );
    }
    if (fullWidth) {
        return (
            <div className="w-full h-full flex flex-col justify-center items-center" style={style}>
                <CircularProgress />
                {text && <Typography className="mt-6">{text}</Typography>}
            </div>
        );
    }
    return <CircularProgress />;
}
