import { Typography } from '@mui/material';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function NotFoundPage() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <div className="flex justify-center items-center flex-col p-20">
            <SentimentDissatisfiedIcon fontSize="inherit" style={{ fontSize: '10rem' }} />
            <Typography className="mt-4 text-3xl font-bold">{t('PAGE_NOT_FOUND')}</Typography>
            <Button onClick={() => navigate('/')} className="mt-6" variant="contained" color="primary">
                {t('GO_TO_FRONT_PAGE')}
            </Button>
        </div>
    );
}
