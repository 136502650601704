import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useEffect, useLayoutEffect, useState } from 'react';

export default function ContentComponent({ pageContent, index, last }) {
    const [viewableHeight, setViewableHeight] = useState(0);

    useLayoutEffect(() => {
        let _viewableHeight = 0;
        document.querySelectorAll('.content-component').forEach(doc => {
            if (!doc.classList.contains(`content-component-${index}`)) {
                _viewableHeight += doc.offsetHeight;
            }
        });
        setViewableHeight(_viewableHeight);
    }, [index]);

    useEffect(() => {
        const resizeFunc = () => {
            let _viewableHeight = 0;
            document.querySelectorAll('.content-component').forEach(doc => {
                if (!doc.classList.contains(`content-component-${index}`)) {
                    _viewableHeight += doc.offsetHeight;
                }
            });
            setViewableHeight(_viewableHeight);
        };
        window.addEventListener('resize', resizeFunc);
        return () => {
            window.removeEventListener('resize', resizeFunc);
        };
    }, [index]);

    return (
        <section
            className={`w-full py-12 content-component content-component-${index}`}
            style={{
                backgroundColor: pageContent?.colors?.backgroundColor || undefined,
                color: pageContent?.colors?.textColor || undefined,
                minHeight: last ? `${window.innerHeight - 95 - viewableHeight}px` : undefined,
                paddingBottom: !last ? '3rem' : undefined
            }}
        >
            {pageContent?.showInPaper && (
                <div className="container px-4 md:px-6 mx-auto">
                    <Paper className="p-6">
                        {pageContent.title && (
                            <Typography className="text-3xl font-bold tracking-tighter mb-10">
                                {pageContent.title}
                            </Typography>
                        )}
                        <div dangerouslySetInnerHTML={{ __html: pageContent.content }} className="ck-content" />
                    </Paper>
                </div>
            )}
            {!pageContent?.showInPaper && (
                <div className="container px-4 md:px-6 mx-auto">
                    {pageContent.title && (
                        <Typography className="pt-6 text-3xl font-bold tracking-tighter mb-10">
                            {pageContent.title}
                        </Typography>
                    )}
                    {pageContent.content && !pageContent?.content?.id && (
                        <div dangerouslySetInnerHTML={{ __html: pageContent.content }} className="ck-content" />
                    )}
                </div>
            )}
        </section>
    );
}
