import { createSlice } from '@reduxjs/toolkit';
import apiService from '../../services/apiService';

export const requestConfig = () => async dispatch => {
    const [navigation, pages, globalSettings] = await Promise.all([
        apiService.get(`/navigation-items?sort=order&pagination[limit]=1000000000`).then(resp => resp.data),
        apiService.get(`/pages?fields=uri&pagination[limit]=1000000000`).then(resp => resp.data),
        apiService
            .get(
                `/global-setting?populate[favicon]=*&populate[pageForNoCouncil][fields][0]=uri&populate[terms_page_for_partner][fields][0]=uri&populate[terms_page_for_coupons][fields][0]=uri&populate[page_for_buy_coupons][fields][0]=uri`
            )
            .then(resp => resp.data)
    ]);
    if (globalSettings?.attributes?.favicon?.data?.attributes?.url) {
        document
            .querySelector('#metaFavicon')
            .setAttribute('href', apiService.getImage(globalSettings?.attributes?.favicon?.data?.attributes?.url));
    }
    dispatch(setConfig({ ...globalSettings.attributes, isLoaded: true, navigation, pages }));
};
const initialState = {
    navigation: null,
    pages: null,
    isLoaded: false,
    pageForNoCouncil: null,
    partner_days: null,
    partner_price: null,
    partner_tax: null
};

const authSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setConfig: (state, action) => action.payload
    }
});

export const { setConfig } = authSlice.actions;

export default authSlice.reducer;

export const selectNavigation = state => state.config.navigation;
export const selectPages = state => state.config.pages;
export const selectPageByUri = (state, uri) =>
    state.config.pages?.find(page => page.attributes.uri?.toLowerCase() === uri?.toLowerCase());
export const selectConfigIsLoaded = state => state.config.isLoaded;
export const selectPageForNoCouncil = state => state.config.pageForNoCouncil;
export const selectPageForCouponTerms = state => state.config.terms_page_for_coupons;
export const selectPageForPartnerTerms = state => state.config.terms_page_for_partner;
export const selectPageForBuyCoupons = state => state.config.page_for_buy_coupons;
export const selectPartnerPrice = state => state.config.partner_price;
export const selectPartnerDays = state => state.config.partner_days;
export const selectPartnerTax = state => state.config.partner_tax;
