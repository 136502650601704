const da = {
    translation: {
        CONFIRM: 'Bekræft',
        CANCEL: 'Annuller',
        CONTINUE: 'Fortsæt',
        NOT_SELECTED: 'Ikke valgt',
        SELECTED_FILE: 'Valgt fil',
        UPLOAD: 'Upload',
        DATETIME_FORMAT: 'DD. MMM YYYY, HH:mm',
        MEDIUM_DATE_FORMAT: 'DD. MMM YYYY',
        SHORT_DATE_FORMAT: 'DD-MM-YYYY',
        UNKNOWN_ERROR: 'Ukendt fejl',
        PAGE_NOT_FOUND: 'Siden blev ikke fundet',
        GO_TO_FRONT_PAGE: 'Gå til forside',
        MISSING_ACCESS: 'Manglende adgang',
        MISSING_ACCESS_TEXT: 'Du har ikke adgang til denne side',
        I_ACCEPT: 'Jeg accepterer',
        TERMS_AND_CONDITIONS: 'handelsbetingelserne',
        TOTAL: 'I alt',
        OF_THIS: 'Heraf',
        TAX: 'moms',
        GO_TO_PAYMENT: 'Gå til betaling',

        // REPORTS
        REPORTS_TYPE: 'Type',
        REPORTS_NAME: 'Navn',
        REPORTS_DATE: 'Dato',
        REPORTS_DOWNLOAD: 'Download',
        REPORTS_TYPE_GENERAL_ASSEMBLY: 'Generalforsamling',
        REPORTS_TYPE_BOARD_MEETING: 'Bestyrelsesmøde',
        REPORTS_SHOW: 'Vis',

        // COUNCIL
        COUNCIL_ROOM: 'Lokale',
        COUNCIL_EMAIL_CHANGED_SUCCESS: 'Rådets email er blevet ændret',
        COUNCIL_ROOM_CHANGED_SUCCESS: 'Rådets lokale er blevet ændret',
        COUNCIL_CONTENT_CHANGED_SUCCESS: 'Rådets indhold er blevet ændret',
        COUNCIL_ADMIN_REMOVED_SUCCESS: 'Admin er blevet fjernet fra rådet',
        COUNCIL_ADMIN_ADDED_SUCCESS: 'Admin er blevet tilføjet til rådet',
        COUNCIL_INFORMATION: 'Oplysninger',
        COUNCIL_INFORMATION_IS_VISIBLE_TO_STUDENTS:
            'Ovenstående oplysninger er synlige for alle brugere med følgende uddannelser:',
        COUNCIL_ADMINS: 'Admins',
        COUNCIL_ADD_ADMIN: 'Tilføj admin',
        COUNCIL_REMOVE_ADMIN: 'Fjern admin',
        COUNCIL_ADMIN_NAME: 'Navn',
        COUNCIL_ADMIN_EMAIL: 'Email',
        COUNCIL_ADMIN_ACTIONS: 'Handlinger',
        COUNCIL_ENTER_EMAIL_FOR_NEW_ADMIN: 'Indtast emailen på den bruger, du vil tilføje som admin for rådet',
        COUNCIL_THIS_ACTION_IS_IRREVERSIBLE: 'Denne handling kan ikke fortrydes og vil',
        COUNCIL_REMOVE_YOU_AS_ADMIN: 'fjerne dig som admin!',
        COUNCIL_ARE_YOU_SURE_YOU_WANT_TO_REMOVE: 'Er du sikker på, at du vil fjerne',
        COUNCIL_AS_AN_ADMIN: 'som admin for rådet',
        COUNCIL_ENTER_YOUR_EMAIL_TO_CONFIRM: 'Indtast email for at bekræfte sletning',
        COUNCIL_CONTENT: 'Indhold',
        COUNCIL_RESET_CONTENT: 'Nulstil',
        COUNCIL_RESET_SAVE: 'Gem',

        // EMAIL
        WRONG_TOKEN_TITLE: 'Forkert token',
        WRONG_TOKEN_ERROR: 'Dit token er ugyldigt. Prøv at anmod om et nyt link.',
        EMAIL_CONFIRMED_TITLE: 'Email bekræftet',
        EMAIL_CONFIRMED_MESSAGE: 'Din sekundær email er blevet bekræftet',
        EMAIL_MAIL_UNSUBSCRIBED_TITLE: 'Afmeldt',
        EMAIL_MAIL_UNSUBSCRIBED: 'Du er nu afmeldt nyhedsbrevet',
        EMAIL_CONFIRM_UNSUBSCRIBE_TITLE: 'Er du sikker?',
        EMAIL_CONFIRM_UNSUBSCRIBE: 'Du er ved at afmelde dig nyhedsbrevet. Er du sikker på at du vil fortsætte?',
        EMAIL_UNSUBSCRIBE: 'Afmeld nyhedsbrev',

        // FAQ
        FAQ_TITLE: 'Vi er her for at hjælpe',
        FAQ_SUBTITLE: 'Ofte stillede spørgsmål',
        FAQ_SEARCH_PLACEHOLDER: 'Søg efter spørgsmål eller svar...',
        FAQ_NO_QUESTIONS: 'Der er ingen spørgsmål at vise',

        // Events
        EVENTS_ALL: 'Alle',
        EVENTS_MONTH: 'Måned',

        // Homepage
        EVENTS: 'Events',
        READ_MORE: 'Læs mere',
        SIGN_UP: 'Tilmeld dig',
        NO_DESCRIPTION: 'Ingen beskrivelse',

        // Navbar
        SEARCH: 'Søg',
        SEARCH_PLACEHOLDER: 'Søg efter noget...',

        // Search page
        SEARCH_RESULTS_FOR: 'Søgeresultater for',
        SEARCH_NO_RESULTS: 'Der er ingen resultater for din søgning',
        SHOW_PDF_BUTTON: 'Vis PDF',
        PAGE_SHOW_MORE_BUTTON: 'Læs mere',
        EVENT_SHOW_MORE_BUTTON: 'Læs mere',

        // Profile menu
        ADMIN_FOR_COUNCIL: 'Admin for',
        YOUR_PROFILE: 'Din profil',
        YOUR_COUPONS: 'Dine klippekort',
        SCAN_MEMBERS: 'Scan medlemmer',
        LOGOUT: 'Log ud',

        // Reset Password Page
        RESET_PASSWORD: 'Nulstil adgangskode',
        RESET_PASSWORD_TEXT: 'Indtast din nye adgangskode.',
        RESET_LINK_INVALID: 'Nulstillingslinket er ugyldigt.',
        CREATE_PASSWORD: 'Opret adgangskode',
        CREATE_PASSWORD_TITLE: 'Opret din adgangskode',

        // Forgot Password Page
        FORGOT_PASSWORD: 'Glemt adgangskode',
        FORGOT_PASSWORD_TEXT: 'Indtast din email for at nulstille din adgangskode.',
        SEND_RESET_LINK: 'Send nulstillingslink',
        RESET_LINK_EMAIL_SENT_TO: 'Nulstillingslink er blevet sendt til',
        CLICK_THE_LINK_TO_RESET: '. Klik på linket for at nulstille din adgangskode.',

        // Register Page
        REGISTER: 'Registrer',
        REGISTER_TO_YOUR_ACCOUNT: 'Registrer dig med din email og kodeord.',
        YOUR_FIRSTNAME: 'Dit fornavn',
        YOUR_LASTNAME: 'Dit efternavn',
        USERNAME: 'Brugernavn',
        YOUR_USERNAME: 'Dit brugernavn',
        YOUR_EDUCATION: 'Dit studie',
        THERE_IS_SENT_AN_EMAIL_TO: 'Der er blevet sendt en email til',
        PASSWORDS_DO_NOT_MATCH: 'Adgangskoderne er ikke ens',
        CREATE_ACCOUNT: 'Opret konto',
        ALREADY_HAVE_AN_ACCOUNT: 'Har du allerede en konto?',
        LOGIN_INSTEAD: 'Log ind i stedet',
        EMAIL_VALIDATION_ERROR: 'Din email skal slutte på',

        // Login Page
        LOGIN_FORGOT_PASSWORD: 'Glemt adgangskode?',
        LOGIN: 'Log ind',
        LOGIN_TO_YOUR_ACCOUNT: 'Log ind med din email og kodeord.',
        YOUR_EMAIL_ARE_NOW_CONFIRMED: 'Din email er blevet bekræftet. Log ind for at fortsætte.',
        CONFIRMATION_EMAIL_SENT_TO: 'Der er blevet sendt en ny email til',
        CLICK_THE_LINK_TO_CONFIRM: '. Klik på linket i emailen for at bekræfte din konto.',
        EMAIL: 'Email',
        YOUR_EMAIL: 'Din email',
        YOUR_PASSWORD: 'Din adgangskode',
        RESEND_CONFIRMATION: 'Gensend bekræftelse',
        DONT_HAVE_AN_ACCOUNT: 'Er du ikke medlem?',
        CREATE_AN_ACCOUNT: 'Bliv medlem',
        WOULD_YOU_TRY_TO_LOGIN_AGAIN: 'Vil du forsøge at logge ind igen?',
        AUTH_ERROR_INVALID_PASSWORD: 'Forkert email eller adgangskode',
        AUTH_ERROR_NOT_CONFIRMED: 'Din email er ikke bekræftet',

        // Profile Page
        FIRSTNAME: 'Fornavn',
        LASTNAME: 'Efternavn',
        CITY: 'By',
        ZIP: 'Postnummer',
        PHONE: 'Telefonnummer',
        ADDRESS: 'Adresse',
        COUNTRY: 'Land',
        PASSWORD: 'Adgangskode',
        PROFILE_EMAIL: 'SDU Mail',
        PROFILE_PARTNER_EMAIL: 'E-mail',
        CONFIRM_PASSWORD: 'Bekræft adgangskode',
        CONFIRM_PASSWORD_CHANGE: 'Bekræft adgangskode',
        CHANGE: 'Ændre',
        ACCOUNT_SETTINGS: 'Kontoindstillinger',
        ROLES: 'Roller',
        EVENT_ATTENDANCE_CONFIRMATION: 'Bekræftelse af begivenhedsdeltagelse',
        CLICK_TO_CONFIRM: 'Klik for at bekræfte',
        MEMBERSHIP: 'Medlemskab',
        MEMBERSHIP_REQUIRED: 'Medlemskab påkrævet',
        MEMBERSHIP_REQUIRED_DESCRIPTION:
            'Du har ikke valgt et medlemskab. Dette er påkrævet for at kunne bruge systemet.',
        SCAN_THIS_CODE_TO_CONFIRM: 'Scan denne kode for at bekræfte',
        EDUCATION: 'Studie',
        TO_CHANGE_YOUR_PASSWORD_YOU_MUST_ENTER_YOUR_CURRENT_PASSWORD:
            'For at ændre din adgangskode, skal du bekræfte din nuværende adgangskode herunder.',
        NOTIFICATIONS: 'Notifikationer',
        NEED_CONFIRMATION_FOR_EDUCATION: 'Mangler bekræftelse på studie',
        PROFILE_NEWSLETTER_VOLUNTEER: 'Kontakt mig, når der skal bruges frivillige',
        PROFILE_NEWSLETTER_EVENTS: 'Jeg giver samtykke til at jeg bliver informeret om events og arrangementer på mail',
        PROFILE_NEWSLETTER_BENEFITS:
            'Jeg giver samtykke til at jeg bliver informeret om medlemsfordele og rabatter på mail',
        CLICK_TO_UNSUBSCRIBE: 'Klik for at afmelde',
        CLICK_TO_SUBSCRIBE: 'Klik for at tilmelde',
        UNSUBSCRIBED: 'Afmeldt',
        SUBSCRIBED: 'Tilmeldt',
        UPLOAD_PROFILE_PICTURE: 'Upload profilbillede',
        SELECT_PROFILE_PICTURE: 'Vælg profilbillede',
        PRIMARY_EMAIL: 'Primær email',
        SECONDARY_EMAIL: 'Sekundær email',
        SECONDARY_EMAIL_HELPERTEXT: 'Dette er din privat email',
        EMAIL_CHANGED_SUCCESS: 'Din email er blevet ændret',
        CHANGE_EMAIL_TITLE: 'Ændre email',
        TO_CHANGE_YOUR_EMAIL_ADDRESS_PLEASE_ENTER_YOUR_NEW_EMAIL_ADDRESS_BELOW_AND_CLICK_CONFIRM:
            'For at ændre din email adresse, skal du indtaste din nye email adresse nedenfor igen og klikke på bekræft.',
        TYPE_YOUR_NEW_EMAIL_AGAIN: 'Indtast din nye email igen',
        YOU_WILL_BE_LOGGED_OUT_AFTER_CHANGING_YOUR_EMAIL: 'Du vil blive logget ud efter at have ændret din email',
        MEMBER_ID: 'Medlems ID',
        YOUR_COUNCIL: 'Dit fagråd',
        NO_COUNCIL_CONNECTED: 'Dit studie har ikke et fagråd tilknyttet, du kan læse mere om fagråd ved at',
        NO_COUNCIL_CLICK_HERE: 'klikke her',
        COUNCIL_FOR_YOUR_EDUCATION: 'For din uddannelse',
        COUNCIL_IS: 'er fagrådet',
        COUNCIL_CLICK_TO_READ_MORE: 'Klik for at læse mere om dit fagråd',
        MEMBERSHIP_EXPIRE: 'Medlemskab udløber',
        PAYMENT_PROCESSING: 'Behandler din betaling. Vent venligst...',
        PAYMENT_REDIRECTING: 'Du bliver nu sendt til betalingssiden. Vent venligst...',
        PAYMENT_SUCCESS: 'Tak for betalingen!',
        PAYMENT_ERROR_TITLE: 'Beklager',
        PAYMENT_ERROR_DESC_1: 'Der skete en fejl i forbindelse med din betaling.',
        PAYMENT_ERROR_DESC_2: 'Vi har derfor ikke trukket nogle penge fra din konto.',
        PAYMENT_SELECT_SUBSCRIPTION_PLAN: 'Vælg medlemskab',
        PAYMENT_SUBSCRIPTION_WILL_EXPIRE_AT: 'Dit medlemskab vil udløbe d.',
        PAYMENT_EDUCATION_REQUIRED: 'Du har ikke valgt et studie. Dette er påkrævet for at fortsætte.',

        // Profile Page Alerts
        NEWSLETTER_SUCCESS_ENABLED: 'Du er nu tilmeldt nyhedsbrevet',
        NEWSLETTER_SUCCESS_DISABLED: 'Du er nu afmeldt nyhedsbrevet',
        NAME_CHANGED_SUCCESS: 'Dit navn er blevet ændret',
        EDUCATION_CHANGED_SUCCESS: 'Dit studie er blevet ændret',
        PASSWORD_CHANGED_SUCCESS: 'Din adgangskode er blevet ændret',
        EDUCATION_REQUIRED: 'Manglende valg af studie',
        EDUCATION_CHANGED_DESCRIPTION: 'Du har ikke valgt et studie. Dette er påkrævet for at kunne bruge systemet.',
        SELECT_MEMBERSHIP: 'Vælg medlemskab',
        LACK_OF_DECLARATION: 'Manglende tilkendegivelse',
        LACK_OF_DECLARATION_DESCRIPTION:
            'Du har ikke taget stilling til om du ønsker at modtage notifikationer fra os.',
        PAYMENT_TITLE: 'Betaling',
        PAYMENT_PENDING: 'Betalingen er under behandling, vent venligst.',
        PAYMENT_AUTHORIZED: 'Betalingen er godkendt, vent venligst.',
        PAYMENT_CAPTURED: 'Betalingen er gennemført.',
        PAYMENT_ERROR: 'Betalingen fejlede, prøv igen senere.',

        // Roles
        UNVERIFIED: 'Ubekræftet',
        VERIFIED: 'Bekræftet',
        MEMBER: 'Medlem',
        PARTNER: 'Partner',

        // COUPON
        COUPON_ADDED_TO_ACCOUNT: 'Klippekort tilknyttet',
        LINK_YOUR_ACCOUNT: 'Tilknyt din bruger',
        COUPON_APPLIED: 'Klippekort indløst',
        COUPON_NOT_APPLIED: 'Klippekort IKKE indløst',
        COUPON_ERROR_TRY_AGAIN_LATER: 'Der skete en fejl. Prøv igen senere.',
        COUPON_REDEEM: 'STRYG FOR AT INDLØSE',
        COUPON_REDEEM_ONLY_BY_STAFF: 'Må kun indløses af bartender',
        COUPON_NOT_FOUND: 'Klippekort ikke fundet',
        COUPON_NOT_FOUND_DESC: 'Det klippekort du søgte, kunne ikke findes',
        COUPON_PROCESSING: 'Behandler betaling...',
        COUPON_PROCESSING_DESC: 'Din betaling bliver behandlet. Vent venligst.',
        COUPON_PAYMENT_ERROR: 'Betaling fejlede',
        COUPON_PAYMENT_ERROR_DESC: 'Din betaling fejlede, og der er ikke blevet trukket penge fra din konto.',

        // COUPONS
        COUPONS_YOUR_COUPONS: 'Dine klippekort',
        COUPONS_YOUR_COUPONS_DESC: 'Her kan du se alle dine klippekort',
        COUPONS_NO_COUPONS: 'Du har ingen klippekort',
        COUPONS_BUY_HERE: 'Køb klippekort her',
        COUPONS_USED: 'Brugt',
        COUPONS_USE: 'Brug klip',
        COUPONS_EXPIRES: 'Udløber',

        // COUPON SHOP
        COUPON_SHOP_BUY: 'Køb klippekort',
        COUPON_SHOP_BUY_TEXT: 'Du er igang med at købe klippekortet',
        COUPON_SHOP_BUY_TEXT_2: 'på',
        COUPON_SHOP_BUY_TEXT_3: 'klip',
        COUPON_SHOP_WHEN_PURCHASED:
            'Når betalingen er godkendt, vil du også modtage en email med et link til dit klippekort.',
        COUPON_SHOP_BUY_FOR: 'Køb for',

        // EVENT ADMIN
        EVENT_ADMIN_SCAN_MEMBERS: 'Scan medlems QR kode',
        EVENT_ADMIN_USER_SCANNED: 'er blevet scannet ind',
        EVENT_ADMIN_USER_ALREADY_SCANNED: 'Brugeren har allerede været scannet ind',
        EVENT_ADMIN_SCAN_WAITING: 'Afventer indscanning',

        // FORMS
        FORMS_NAME: 'Navn',
        FORMS_DATE: 'Dato',
        FORMS_GO_TO: 'Gå til',

        // FORM
        FORM_ANSWER: 'Svar',
        FORM_NEW_ANSWER: 'Nyt svar',
        FORM_ANSWERED_BEFORE: 'Tidligere besvaret',
        FORM_ANSWERED_BEFORE_DESC:
            'Du har tidligere besvaret denne formular. Du kan se dine svar ved at vælge et svar til højre.',
        FORM_ALREADY_ANSWERED: 'Allerede besvaret',
        FORM_ALREADY_ANSWERED_DESC: 'Du har allerede besvaret denne formular. Du kan se dine svar herunder.',
        FORM_MORE_THAN_ONE_ANSWER:
            'Du har givet mere end et svar, du kan derfor vælge mellem dem i dropdownen til højre.',
        FORM_CHANGE_ANSWER: 'Du har mulighed for at ændre dine svar, ved at klikke ind på svaret.',
        FORM_REQUIRE_LOGIN: 'Denne formular kræver at du er logget ind for at kunne besvare den.',
        FORM_NOT_ACCEPTING_ANSWERS: 'Denne formular tager ikke imod svar længere.',
        FORM_INVALID_EMAIL: 'Ukorrekt email',
        FORM_REQUIRED_FIELD: 'Dette felt er påkrævet',
        FORM_EDIT_ANSWER: 'Rediger',
        FORM_SUBMIT_ANSWER: 'Indsend',

        // POST TYPES
        POST_TYPE_INTERNSHIP: 'Praktikplads',
        POST_TYPE_STUDENT_JOB: 'Studiejob',
        POST_TYPE_FULL_TIME_JOB: 'Fuldtidsjob',
        POST_TYPE_PROJECT: 'Projekt',

        // POSTS
        POSTS: 'Opslag',
        POSTS_EDUCATION: 'Uddannelse',
        POSTS_ALL: 'Alle',
        POSTS_TYPE: 'Type',
        POSTS_NO_TO_SHOW: 'Ingen opslag at vise',

        // POST PAGE
        POST_GO_BACK: 'Gå tilbage',
        POST_EDIT: 'Rediger',
        POST_DELETE: 'Slet',

        // MY POSTS PAGE
        MY_POSTS: 'Dine opslag',
        MY_POSTS_DELETED: 'Opslag er blevet slettet',
        MY_POSTS_VISIBILITY_SHOW: 'Opslaget er blevet synligt for brugere',
        MY_POSTS_VISIBILITY_HIDDEN: 'Opslaget er blevet skjult for brugere',
        MY_POSTS_CREATE: 'Opret opslag',
        MY_POSTS_TITLE: 'Titel',
        MY_POSTS_TYPE: 'Type',
        MY_POSTS_EDUCATIONS: 'Uddannelser',
        MY_POSTS_CREATED: 'Oprettet',
        MY_POSTS_UPDATED: 'Sidst Opdateret',
        MY_POSTS_PREVIEW: 'Vis',
        MY_POSTS_HIDE: 'Skjul for brugere',
        MY_POSTS_SHOW: 'Vis for brugere',
        MY_POSTS_MISSING_PARTNER: 'Du mangler at betale for at kunne benytte denne funktion',
        MY_POSTS_CONFIRM_DELETE: 'Bekræft sletning',
        MY_POSTS_CONFIRM_DELETE_BUTTON: 'Slet',
        MY_POSTS_ARE_YOU_SURE: 'Er du sikker på at du vil slette opslaget',
        MY_POSTS_ALL_EDUCATIONS: 'Alle uddannelser',

        // MY POST PAGE
        MY_POST_CHANGES_NOT_SAVED: 'Du har ikke gemt dine ændringer. Vil du fortsætte?',
        MY_POST_CREATED: 'Dit opslag er blevet oprettet',
        MY_POST_UPDATED: 'Dit opslag er blevet opdateret',
        MY_POST_CREATE: 'Opret opslag',
        MY_POST_UPDATE: 'Rediger opslag',
        MY_POST_LANGUAGE: 'Sprog',
        MY_POST_PUBLISH: 'Offentliggør',
        MY_POST_NOT_PUBLISHED_YET_TITLE: 'Bemærk',
        MY_POST_NOT_PUBLISHED_YET_DESC:
            'Opslaget er endnu ikke synligt for andre brugere. Du kan gør det synligt ved at klikke på knappen til højre.',
        MY_POST_INFORMATION: 'Informationer',
        MY_POST_TITLE: 'Titel',
        MY_POST_LOCATION: 'Lokation',
        MY_POST_SETTINGS: 'Indstillinger',
        MY_POST_SAME_FOR_ALL_LANGUAGES: 'Disse indstillinger er ens for alle sprog',
        MY_POST_TYPE: 'Type',
        MY_POST_EDUCATIONS: 'Uddannelser',
        MY_POST_EDUCATIONS_HELPER: 'Vælger du ingen uddannelser, vil opslaget være for alle uddannelser',
        MY_POST_SHORT_DESCRIPTION: 'Kort beskrivelse',
        MY_POST_MAX_LENGTH: 'Du har nået maksimum antal karakterer',
        MY_POST_DESCRIPTION: 'Beskrivelse',
        MY_POST_CREATE_BUTTON: 'Opret opslag',
        MY_POST_SAVE_BUTTON: 'Gem ændringer'
    }
};

export default da;
