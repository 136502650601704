import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useMemo } from 'react';

export default function TextFieldForm({ control, ...props }) {
    const type = useMemo(() => {
        if (props.type === 'email') {
            return 'text';
        }
        return props.type;
    }, [props]);

    const rules = useMemo(() => {
        let _rules = {};
        if (props.required) {
            _rules.required = 'Dette felt er påkrævet';
        }
        if (props.type === 'email') {
            _rules.pattern = {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Ugyldig email'
            };
        }
        if (props.rules) {
            _rules = { ..._rules, ...props.rules };
        }
        return _rules;
    }, [props]);

    return (
        <Controller
            name={props.name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => (
                <TextField
                    {...field}
                    {...props}
                    type={type}
                    helperText={fieldState?.error?.message}
                    error={!!fieldState?.error}
                />
            )}
        />
    );
}
