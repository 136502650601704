import { useMemo, useState } from 'react';
import { Alert, Card, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import apiService from '../../services/apiService';

export default function EmailPage() {
    const query = new URLSearchParams(window.location.search);
    const event = query.get('e');
    const { t } = useTranslation();
    const [unsubscribed, setUnsubscribed] = useState(false);
    const [loading, setLoading] = useState(false);

    const error = useMemo(() => {
        if (event === 'wrong-token') {
            return t('WRONG_TOKEN_ERROR');
        }
        return null;
    }, [event, t]);

    const title = useMemo(() => {
        if (event === 'wrong-token') {
            return t('WRONG_TOKEN_TITLE');
        }
        if (event === 'confirmed-secondary') {
            return t('EMAIL_CONFIRMED_TITLE');
        }
        if (event === 'unsubscribe') {
            if (unsubscribed) {
                return t('EMAIL_MAIL_UNSUBSCRIBED_TITLE');
            }
            return t('EMAIL_CONFIRM_UNSUBSCRIBE_TITLE');
        }
        return null;
    }, [event, t, unsubscribed]);

    const message = useMemo(() => {
        if (event === 'confirmed-secondary') {
            return t('EMAIL_CONFIRMED_MESSAGE');
        }
        if (event === 'unsubscribe') {
            if (unsubscribed) {
                return t('EMAIL_MAIL_UNSUBSCRIBED');
            }
            return t('EMAIL_CONFIRM_UNSUBSCRIBE');
        }
        return null;
    }, [event, t, unsubscribed]);

    function handleUnsubscribe() {
        setLoading(true);
        apiService
            .get(`/users/unsubscribe-mail?token=${query.get('t')}&type=${query.get('ty')}`)
            .then(() => {
                setLoading(false);
                setUnsubscribed(true);
            })
            .catch(() => {
                setLoading(false);
            });
    }

    return (
        <div className="h-screen bg-[#E5E7EB] py-20">
            <Card className="mx-auto p-8 w-96 rounded-lg shadow-lg">
                <CardContent className="space-y-4">
                    {title && (
                        <Typography variant="h6" className="text-2xl">
                            {title}
                        </Typography>
                    )}
                    {message && <Typography>{message}</Typography>}
                    {error && (
                        <Alert severity="error" className="-mx-12 -mt-4">
                            {error}
                        </Alert>
                    )}
                    {event === 'unsubscribe' && !unsubscribed && (
                        <LoadingButton
                            loading={loading}
                            className="w-full"
                            variant="outlined"
                            color="error"
                            onClick={handleUnsubscribe}
                        >
                            {t('EMAIL_UNSUBSCRIBE')}
                        </LoadingButton>
                    )}
                </CardContent>
            </Card>
        </div>
    );
}
