import React from 'react';
import Icon from '@mui/material/Icon';
import Flags from 'country-flag-icons/react/3x2';

function FlagIcon({ flagCountryCode, className }) {
    const Flag = Flags[flagCountryCode];

    return (
        <Icon className={className}>
            <Flag />
        </Icon>
    );
}

export default FlagIcon;
