import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translation/en';
import da from './translation/da';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en,
    da
};

let language = localStorage.getItem('language');
if (language) {
    language = JSON.parse(language);
} else {
    language = { isoCode: 'da' };
}

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: language.isoCode,
        fallbackLng: 'da',

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
