import { useEffect, useMemo, useRef, useState } from 'react';
import {
    Alert,
    Autocomplete,
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    Icon,
    Link as MuiLink,
    TextField,
    Typography
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import LayoutHeader from '../../layout/LayoutHeader';
import TextFieldForm from '../../ui-components/TextFieldForm';
import {
    createUser,
    login,
    selectAuthError,
    selectRegisterSuccess,
    selectUser,
    setAuthError
} from '../../store/shared/authSlice';
import apiService from '../../services/apiService';

export default function RegisterPage() {
    // const dispatch = useDispatch();
    const user = useSelector(selectUser);
    // const authError = useSelector(selectAuthError);
    // const registerSuccess = useSelector(selectRegisterSuccess);
    const navigate = useNavigate();
    // const { handleSubmit, control, formState, watch, reset } = useForm({
    //     mode: 'onChange',
    //     defaultValues: {
    //         firstname: '',
    //         lastname: '',
    //         email: '',
    //         password: '',
    //         passwordConfirm: ''
    //     }
    // });
    // const { isDirty, isValid, isSubmitting } = formState;
    // const form = watch();
    const { t } = useTranslation();
    // const [educations, setEducations] = useState(null);

    // useEffect(() => {
    //     apiService
    //         .get('/educations?populate=name')
    //         .then(response => {
    //             setEducations(response.data.map(e => ({ ...e.attributes, id: e.id })));
    //         })
    //         .catch(console.error);
    // }, []);

    // useEffect(() => {
    //     dispatch(setAuthError(null));
    // }, [dispatch]);

    useEffect(() => {
        if (user) {
            navigate('/');
        }
    }, [navigate, user]);

    // function onSubmit(data) {
    //     dispatch(createUser(data.email, data.email, data.password, data.firstname, data.lastname, data.education.id));
    // }

    // useEffect(() => {
    //     if (registerSuccess?.user) {
    //         reset(registerSuccess.user);
    //     }
    // }, [registerSuccess, reset]);

    return (
        <div className="bg-[#E5E7EB] h-screen">
            <LayoutHeader />
            <div
                style={{
                    paddingTop: '95px'
                }}
            >
                <div className="h-20">
                    <div className="h-full mx-auto w-[950px]">
                        <Grid container className="h-full">
                            <Grid item xs={3}>
                                <div className="flex items-center h-full">
                                    <Button component={Link} to="/login" startIcon={<Icon>arrow_back</Icon>}>
                                        {t('LOGIN_INSTEAD')}
                                    </Button>
                                </div>
                            </Grid>
                            <Grid item xs={6} className="h-full">
                                <div className="flex justify-center items-center h-full">
                                    <Typography variant="h6" className="text-2xl">
                                        {t('REGISTER')}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
            <div className="w-full md:w-[950px] mx-auto" style={{ height: 'calc(100% - 5rem - 102px)' }}>
                <iframe
                    title="indmeld"
                    width="100%"
                    height="100%"
                    className="border-none"
                    src="https://sif.foreningsadministrator.dk/iframe/indmeld"
                />
            </div>
            {/* <div */}
            {/*    style={{ */}
            {/*        paddingTop: '95px' */}
            {/*    }} */}
            {/* > */}
            {/*    <Card className="mx-auto my-10 sm:my-20 p-2 sm:p-8 w-full sm:w-[28rem] rounded-lg shadow-lg"> */}
            {/*        <form onSubmit={handleSubmit(onSubmit)}> */}
            {/*            <CardContent className="space-y-4"> */}
            {/*                <Typography variant="h6" className="text-2xl"> */}
            {/*                    {t('REGISTER')} */}
            {/*                </Typography> */}
            {/*                <Typography>{t('REGISTER_TO_YOUR_ACCOUNT')}</Typography> */}

            {/*                {authError && ( */}
            {/*                    <Alert severity="error" className="-mx-12 -mt-4"> */}
            {/*                        {authError.message} */}
            {/*                    </Alert> */}
            {/*                )} */}

            {/*                {registerSuccess?.user?.email && ( */}
            {/*                    <Alert severity="success" className="-mx-12 -mt-4"> */}
            {/*                        {t('THERE_IS_SENT_AN_EMAIL_TO')} {registerSuccess?.user?.email} */}
            {/*                        {t('CLICK_THE_LINK_TO_CONFIRM')} */}
            {/*                    </Alert> */}
            {/*                )} */}

            {/*                <div className="flex items-center justify-between"> */}
            {/*                    <div className="mr-1"> */}
            {/*                        <label className="block text-sm font-medium text-gray-700" htmlFor="firstname"> */}
            {/*                            {t('FIRSTNAME')} */}
            {/*                        </label> */}
            {/*                        <TextFieldForm */}
            {/*                            control={control} */}
            {/*                            size="small" */}
            {/*                            placeholder={t('YOUR_FIRSTNAME')} */}
            {/*                            className="w-full" */}
            {/*                            id="firstname" */}
            {/*                            name="firstname" */}
            {/*                            required */}
            {/*                            disabled={!!registerSuccess} */}
            {/*                        /> */}
            {/*                    </div> */}
            {/*                    <div className="ml-1"> */}
            {/*                        <label className="block text-sm font-medium text-gray-700" htmlFor="lastname"> */}
            {/*                            {t('LASTNAME')} */}
            {/*                        </label> */}
            {/*                        <TextFieldForm */}
            {/*                            control={control} */}
            {/*                            size="small" */}
            {/*                            placeholder={t('YOUR_LASTNAME')} */}
            {/*                            className="w-full" */}
            {/*                            id="lastname" */}
            {/*                            name="lastname" */}
            {/*                            required */}
            {/*                            disabled={!!registerSuccess} */}
            {/*                        /> */}
            {/*                    </div> */}
            {/*                </div> */}
            {/*                /!* <div className=""> *!/ */}
            {/*                /!*    <label className="block text-sm font-medium text-gray-700" htmlFor="username"> *!/ */}
            {/*                /!*        {t('USERNAME')} *!/ */}
            {/*                /!*    </label> *!/ */}
            {/*                /!*    <TextFieldForm *!/ */}
            {/*                /!*        control={control} *!/ */}
            {/*                /!*        autoComplete="username" *!/ */}
            {/*                /!*        size="small" *!/ */}
            {/*                /!*        placeholder={t('YOUR_USERNAME')} *!/ */}
            {/*                /!*        className="w-full" *!/ */}
            {/*                /!*        id="username" *!/ */}
            {/*                /!*        name="username" *!/ */}
            {/*                /!*        required *!/ */}
            {/*                /!*        disabled={!!registerSuccess} *!/ */}
            {/*                /!*    /> *!/ */}
            {/*                /!* </div> *!/ */}
            {/*                <div className=""> */}
            {/*                    <label className="block text-sm font-medium text-gray-700" htmlFor="username"> */}
            {/*                        {t('EDUCATION')} */}
            {/*                    </label> */}
            {/*                    <AutocompleteForm */}
            {/*                        control={control} */}
            {/*                        id="education" */}
            {/*                        name="education" */}
            {/*                        options={educations || []} */}
            {/*                        loading={educations === null} */}
            {/*                        getOptionLabel={option => option.name} */}
            {/*                        isOptionEqualToValue={(option, value) => option.id === value.id} */}
            {/*                        className="w-full" */}
            {/*                        size="small" */}
            {/*                        renderInput={params => <TextField {...params} placeholder={t('YOUR_EDUCATION')} />} */}
            {/*                        disableClearable */}
            {/*                    /> */}
            {/*                </div> */}
            {/*                <div className=""> */}
            {/*                    <label className="block text-sm font-medium text-gray-700" htmlFor="email"> */}
            {/*                        {t('EMAIL')} */}
            {/*                    </label> */}
            {/*                    <TextFieldForm */}
            {/*                        control={control} */}
            {/*                        autoComplete="email" */}
            {/*                        size="small" */}
            {/*                        placeholder={t('YOUR_EMAIL')} */}
            {/*                        className="w-full" */}
            {/*                        id="email" */}
            {/*                        name="email" */}
            {/*                        required */}
            {/*                        type="email" */}
            {/*                        disabled={!!registerSuccess} */}
            {/*                        rules={{ */}
            {/*                            pattern: { */}
            {/*                                value: new RegExp( */}
            {/*                                    `^[A-Z0-9._%+-]+${process.env.REACT_APP_PRIMARY_EMAIL_VALIDATION}$`, */}
            {/*                                    'i' */}
            {/*                                ), */}
            {/*                                message: `${t('EMAIL_VALIDATION_ERROR')} ${ */}
            {/*                                    process.env.REACT_APP_PRIMARY_EMAIL_VALIDATION */}
            {/*                                }` */}
            {/*                            } */}
            {/*                        }} */}
            {/*                    /> */}
            {/*                </div> */}
            {/*                <div className=""> */}
            {/*                    <label className="block text-sm font-medium text-gray-700" htmlFor="password"> */}
            {/*                        {t('PASSWORD')} */}
            {/*                    </label> */}
            {/*                    <TextFieldForm */}
            {/*                        control={control} */}
            {/*                        autoComplete="current-password" */}
            {/*                        size="small" */}
            {/*                        className="w-full" */}
            {/*                        placeholder={t('YOUR_PASSWORD')} */}
            {/*                        id="password" */}
            {/*                        name="password" */}
            {/*                        required */}
            {/*                        type="password" */}
            {/*                        disabled={!!registerSuccess} */}
            {/*                    /> */}
            {/*                </div> */}
            {/*                <div className=""> */}
            {/*                    <label className="block text-sm font-medium text-gray-700" htmlFor="passwordConfirm"> */}
            {/*                        {t('CONFIRM_PASSWORD')} */}
            {/*                    </label> */}
            {/*                    <TextFieldForm */}
            {/*                        control={control} */}
            {/*                        size="small" */}
            {/*                        className="w-full" */}
            {/*                        placeholder={t('CONFIRM_PASSWORD')} */}
            {/*                        id="passwordConfirm" */}
            {/*                        name="passwordConfirm" */}
            {/*                        required */}
            {/*                        type="password" */}
            {/*                        rules={{ */}
            {/*                            validate: value => form.password === value || t('PASSWORDS_DO_NOT_MATCH') */}
            {/*                        }} */}
            {/*                        disabled={!!registerSuccess} */}
            {/*                    /> */}
            {/*                </div> */}
            {/*                <LoadingButton */}
            {/*                    className="w-full py-2 px-4" */}
            {/*                    variant="contained" */}
            {/*                    color="primary" */}
            {/*                    type="submit" */}
            {/*                    loading={isSubmitting} */}
            {/*                    disabled={!isDirty || !isValid || !form.education} */}
            {/*                > */}
            {/*                    {t('CREATE_ACCOUNT')} */}
            {/*                </LoadingButton> */}
            {/*            </CardContent> */}
            {/*        </form> */}
            {/*        <CardActions> */}
            {/*            <div className="flex justify-center flex-col items-center w-full text-gray-600"> */}
            {/*                <Typography>{t('ALREADY_HAVE_AN_ACCOUNT')}</Typography> */}
            {/*                <MuiLink className="text-blue-500 hover:underline" to="/login" component={Link}> */}
            {/*                    {t('LOGIN_INSTEAD')} */}
            {/*                </MuiLink> */}
            {/*            </div> */}
            {/*        </CardActions> */}
            {/*    </Card> */}
            {/* </div> */}
        </div>
    );
}
