import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoaded, validate } from './shared/authSlice';
import Loading from '../ui-components/Loading';

export default function Auth({ children }) {
    const dispatch = useDispatch();
    const loaded = useSelector(selectLoaded);

    useEffect(() => {
        dispatch(validate());
    }, [dispatch]);

    if (!loaded) {
        return <Loading fullscreen />;
    }

    return children;
}
