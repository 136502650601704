import { Autocomplete, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';

export default function Counter({ date }) {
    const [value, setValue] = useState('00:00:000');

    useEffect(() => {
        const timer = setInterval(() => {
            const duration = moment.duration(moment().diff(date));
            let minutes = duration.minutes();
            if (minutes <= 9) {
                minutes = `0${minutes}`;
            }
            let seconds = duration.seconds();
            if (seconds <= 9) {
                seconds = `0${seconds}`;
            }
            setValue(`${minutes}:${seconds}`);
        }, 1);

        return () => {
            clearInterval(timer);
        };
    }, [date]);

    return (
        <div>
            <h1>{value}</h1>
        </div>
    );
}
