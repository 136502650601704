import { Alert, Button, Card, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment/moment';
import { Link, useSearchParams } from 'react-router-dom';
import apiService from '../services/apiService';
import allLanguages from '../translation/languages';

export default function SearchPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const { t, i18n } = useTranslation();
    const [results, setResults] = useState([]);

    useEffect(() => {
        if (searchParams.get('q')) {
            apiService
                .get(
                    `/fuzzy-search/search?query=${searchParams.get(
                        'q'
                    )}&populate[pages][content]=*&populate[pages][title]=*&populate[reports]=pdf`,
                    {
                        params: {
                            locale: null
                        }
                    }
                )
                .then(response => {
                    setResults(response);
                });
        }
    }, [searchParams]);

    const totalResults = useMemo(() => {
        return (results?.pages?.length || 0) + (results?.events?.length || 0) + (results?.reports?.length || 0);
    }, [results]);

    const currentLanguage = useMemo(() => {
        return Object.values(allLanguages).find(language => {
            return language.isoCode === i18n.language;
        });
    }, [i18n.language]);

    return (
        <div className="min-h-screen bg-[#E5E7EB] py-10">
            <div className="max-w-3xl px-4 md:px-6 mx-auto">
                <Typography className="mb-10 text-center text-xl">
                    {t('SEARCH_RESULTS_FOR')} <strong>{searchParams.get('q')}</strong>
                </Typography>
                {totalResults === 0 && (
                    <Alert severity="warning" className="">
                        {t('SEARCH_NO_RESULTS')}
                    </Alert>
                )}
                <div className="flex flex-col gap-4">
                    {results?.events?.map(event => (
                        <Card className="p-2 rounded-lg shadow-lg">
                            <CardContent>
                                <div className={clsx('flex justify-between items-start')}>
                                    <Typography className="text-lg font-bold">{event.name}</Typography>
                                    <Typography
                                        className={clsx(
                                            'text-xs font-medium me-2 px-2.5 py-0.5 rounded text-nowrap min-w-[5.8rem]',
                                            event.isEnded ? 'bg-gray-300 text-gray-700' : 'bg-primary text-white'
                                        )}
                                    >
                                        {moment(event.start).format(t('MEDIUM_DATE_FORMAT'))}
                                    </Typography>
                                </div>
                                <Button
                                    component={Link}
                                    to={`/event/${event.id}`}
                                    variant="outlined"
                                    size="small"
                                    className="mt-4"
                                >
                                    {t('EVENT_SHOW_MORE_BUTTON')}
                                </Button>
                            </CardContent>
                        </Card>
                    ))}
                    {results?.pages?.map(page => (
                        <Card className="p-2 rounded-lg shadow-lg">
                            <CardContent>
                                <div className={clsx('flex justify-between items-start')}>
                                    <Typography className="text-lg font-bold">
                                        {page.title?.[currentLanguage.translationKey]}
                                    </Typography>
                                </div>
                                <Button
                                    component={Link}
                                    to={`${page.uri}`}
                                    variant="outlined"
                                    size="small"
                                    className="mt-4"
                                >
                                    {t('PAGE_SHOW_MORE_BUTTON')}
                                </Button>
                            </CardContent>
                        </Card>
                    ))}
                    {results?.reports?.map(report => (
                        <Card className="p-2 rounded-lg shadow-lg">
                            <CardContent>
                                <div className={clsx('flex justify-between items-start')}>
                                    <Typography className="text-lg font-bold">{report.name}</Typography>
                                    <Typography
                                        className={clsx(
                                            'text-xs font-medium me-2 px-2.5 py-0.5 rounded text-nowrap min-w-[5.8rem]',
                                            'bg-primary text-white'
                                        )}
                                    >
                                        {moment(report.createdAt).format(t('MEDIUM_DATE_FORMAT'))}
                                    </Typography>
                                </div>
                                <Button
                                    onClick={() => {
                                        window.open(apiService.getImage(report.pdf.url), '_blank');
                                    }}
                                    variant="outlined"
                                    size="small"
                                    className="mt-4"
                                >
                                    {t('SHOW_PDF_BUTTON')}
                                </Button>
                            </CardContent>
                        </Card>
                    ))}
                </div>
            </div>
        </div>
    );
}
