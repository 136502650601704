import { combineReducers } from '@reduxjs/toolkit';
import auth from './shared/authSlice';
import config from './shared/configSlice';
import message from './shared/messageSlice';

const createReducer = asyncReducers => (state, action) => {
    const combinedReducer = combineReducers({
        auth,
        config,
        message,
        ...asyncReducers
    });

    /*
	Reset the redux store when user logged out
	 */
    if (action.type === 'auth/user/userLoggedOut') {
        // state = undefined;
    }

    return combinedReducer(state, action);
};

export default createReducer;
