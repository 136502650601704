import { Badge, Button, ButtonGroup, Card, CardContent, Grid, Icon, Typography } from '@mui/material';
import moment from 'moment';
import clsx from 'clsx';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import apiService from '../../services/apiService';
import Loading from '../../ui-components/Loading';

export default function PostPage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [post, setPost] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        apiService
            .get(
                `/posts/${id}?populate[0]=title&populate[1]=location&populate[2]=description&populate[3]=createdAt&populate[4]=updatedAt&populate[5]=educations&populate[6]=educations.name&populate[7]=user&defaultLocale=da`
            )
            .then(response => {
                setPost(response.data);
            });
    }, [id]);

    if (!post) {
        return <Loading fullscreen />;
    }

    return (
        <div
            className="bg-[#E5E7EB]"
            style={{
                minHeight: 'calc(100vh - 95px)'
            }}
        >
            <div
                className="container px-4 md:px-6 mx-auto"
                style={{
                    paddingBottom: '10rem'
                }}
            >
                <div className="flex justify-between py-4">
                    <Button onClick={() => navigate(-1)} startIcon={<Icon>arrow_backwards</Icon>}>
                        {t('POST_GO_BACK')}
                    </Button>
                    {post?.attributes?.isOwner && (
                        <ButtonGroup>
                            <Button startIcon={<Icon>edit</Icon>} onClick={() => navigate(`/mine-opslag/${post.id}`)}>
                                {t('POST_EDIT')}
                            </Button>
                            <Button
                                color="error"
                                startIcon={<Icon>delete</Icon>}
                                onClick={() => navigate(`/mine-opslag?delete=${post.id}`)}
                            >
                                {t('POST_DELETE')}
                            </Button>
                        </ButtonGroup>
                    )}
                </div>
                <Card className={clsx('rounded-lg h-full')}>
                    <CardContent className="relative">
                        <Grid container spacing={4}>
                            <Grid item sm={12} className="w-full">
                                <div className={clsx('flex justify-between items-start')}>
                                    <Typography className="text-3xl font-bold tracking-tighter">
                                        {post.attributes.title}
                                    </Typography>
                                    <Typography
                                        className={clsx(
                                            'text-xs font-medium px-2.5 py-0.5 rounded text-nowrap bg-primary text-white'
                                        )}
                                    >
                                        {t(`POST_TYPE_${post.attributes.type.toUpperCase()}`)}
                                    </Typography>
                                </div>
                                <div className="flex justify-between items-start">
                                    <Typography className="text-sm italic text-ellipsis">
                                        {post.attributes.location}
                                    </Typography>
                                    <Typography className={clsx('text-xs italic text-ellipsis')}>
                                        {moment(post.attributes.createdAt).format(t('DATETIME_FORMAT'))}
                                    </Typography>
                                </div>
                                <Typography className="text-sm mt-4 mb-[2rem] overflow-hidden" color="textSecondary">
                                    {post.attributes.description && (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: post.attributes.description
                                            }}
                                            className="ck-content"
                                        />
                                    )}
                                    {!post.attributes.description && t('NO_DESCRIPTION')}
                                </Typography>
                                <div className="absolute bottom-[1rem]">
                                    <div className="flex flex-wrap gap-2">
                                        {post.attributes.educations.data.length === 0 && (
                                            <Badge className="text-xs font-medium px-2.5 py-0.5 rounded bg-green-600 text-white">
                                                {t('MY_POSTS_ALL_EDUCATIONS')}
                                            </Badge>
                                        )}
                                        {post.attributes.educations.data.map(education => (
                                            <Badge className="text-xs font-medium px-2.5 py-0.5 rounded bg-green-600 text-white">
                                                {education.attributes.name}
                                            </Badge>
                                        ))}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}
