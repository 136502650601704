import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FlagIcon from './FlagIcon';
import allLanguages from '../translation/languages';

function LanguageSwitcher() {
    const [menu, setMenu] = useState(null);

    const { i18n } = useTranslation();

    const currentLanguage = useMemo(() => {
        return Object.values(allLanguages).find(language => {
            return language.isoCode === i18n.language;
        });
    }, [i18n.language]);

    const langMenuClick = event => {
        setMenu(event.currentTarget);
    };

    const langMenuClose = () => {
        setMenu(null);
    };

    async function handleLanguageChange(language) {
        const _currentLanguage = {
            isoCode: language.isoCode,
            title: language.title,
            flag: language.flag
        };
        localStorage.setItem('language', JSON.stringify(_currentLanguage));
        await i18n.changeLanguage(_currentLanguage.isoCode);
        langMenuClose();
        window.location.reload();
    }

    return (
        <>
            <Button onClick={langMenuClick}>
                <div className="flex items-center">
                    <FlagIcon className="-mt-2" flagCountryCode={currentLanguage.flag} />

                    <Typography
                        className="mx-1.5 font-bold"
                        sx={{
                            color: 'text.primary'
                        }}
                    >
                        {currentLanguage.isoCode}
                    </Typography>
                </div>
            </Button>

            <Popover
                open={Boolean(menu)}
                anchorEl={menu}
                onClose={langMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                // classes={{
                //     paper: 'py-8',
                // }}
            >
                {Object.values(allLanguages).map(language => (
                    <MenuItem className="py-3" key={language.isoCode} onClick={() => handleLanguageChange(language)}>
                        <ListItemIcon className="min-w-40">
                            <FlagIcon className="-mt-2" flagCountryCode={language.flag} />
                        </ListItemIcon>
                        <ListItemText className="capitalize" primary={language.title} />
                    </MenuItem>
                ))}
            </Popover>
        </>
    );
}

export default LanguageSwitcher;
