import {
    Button,
    ButtonGroup,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    Link as MUILink,
    ListItemText,
    MenuItem,
    Select,
    Typography,
    useMediaQuery
} from '@mui/material';
import moment from 'moment';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import apiService from '../services/apiService';

export default function EventsComponent({ pageContent }) {
    const [events, setEvents] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const { t } = useTranslation();
    const matches = useMediaQuery('(min-width:600px)');

    const selectableMonths = useMemo(() => {
        const now = moment();
        if (pageContent.showAll) {
            return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        }
        if (now.isAfter(moment('01-07', 'DD-MM'))) {
            return [7, 8, 9, 10, 11, 12];
        }
        return [1, 2, 3, 4, 5, 6];
    }, [pageContent]);

    useEffect(() => {
        let filter = '';
        if (selectedMonth) {
            const start = moment(selectedMonth, 'MM').startOf('month').format('YYYY-MM-DD');
            const end = moment(selectedMonth, 'MM').endOf('month').format('YYYY-MM-DD');
            filter = `&filters[start][$gte]=${start}&filters[start][$lte]=${end}`;
        } else {
            // filter = `&filters[start][$gte]=${moment(selectableMonths[0], 'MM').startOf('month').format('YYYY-MM-DD')}`;
            const today = moment().startOf('day');
            filter = `&filters[start][$gte]=${today.format('YYYY-MM-DD')}`;
        }
        apiService.get(`/events?sort=start${filter}&pagination[limit]=1000000000`).then(response => {
            setEvents(
                response.data.map(e => ({
                    ...e,
                    isEnded: moment().isAfter(moment(e.attributes.end).add('1', 'day'))
                }))
            );
        });
    }, [selectedMonth, selectableMonths]);

    const eventsGroupedByMonth = useMemo(() => {
        const grouped = {};
        events.forEach(event => {
            const month = moment(event.attributes.start).format('YYYY-MM');
            if (!grouped[month]) {
                grouped[month] = { events: [], amountEnded: 0 };
            }
            grouped[month].amountEnded += event.isEnded ? 1 : 0;
            grouped[month].events.push(event);
        });
        const _eventsGroupedByMonth = Object.entries(grouped).map(([month, { events: eventsByMonth, amountEnded }]) => {
            return {
                month,
                eventsByMonth,
                amountEnded
            };
        });
        _eventsGroupedByMonth.sort((a, b) => {
            return moment(a.month, 'YYYY-MM').isBefore(moment(b.month, 'YYYY-MM')) ? -1 : 1;
        });
        return _eventsGroupedByMonth;
    }, [events]);

    function handleClickMonth(month) {
        setSelectedMonth(oldMonth => {
            if (oldMonth === month) {
                return null;
            }
            return month;
        });
    }
    return (
        <div
            className="container space-y-12 px-4 md:px-6 mx-auto"
            style={{
                paddingBottom: '10rem'
            }}
        >
            <div className="flex justify-between items-center pt-6">
                <Typography className="text-3xl font-bold tracking-tighter">{t('EVENTS')}</Typography>
                {/* <div> */}
                {/*    <FormControl sx={{ m: 1, width: 120 }} size="small"> */}
                {/*        <InputLabel id="month-label">{t('EVENTS_MONTH')}</InputLabel> */}
                {/*        <Select */}
                {/*            labelId="demo-simple-select-label" */}
                {/*            id="demo-simple-select" */}
                {/*            value={selectedMonth ?? 'all'} */}
                {/*            label={t('EVENTS_MONTH')} */}
                {/*            onChange={e => */}
                {/*                handleClickMonth(e.target.value === 'all' ? selectedMonth : e.target.value) */}
                {/*            } */}
                {/*        > */}
                {/*            <MenuItem key="all" value="all"> */}
                {/*                {t('EVENTS_ALL')} */}
                {/*            </MenuItem> */}
                {/*            {selectableMonths.map(month => ( */}
                {/*                <MenuItem key={month} value={month}> */}
                {/*                    {moment(month, 'MM').format('MMMM').substring(0, 1).toUpperCase()} */}
                {/*                    {moment(month, 'MM').format('MMMM').substring(1)} */}
                {/*                </MenuItem> */}
                {/*            ))} */}
                {/*        </Select> */}
                {/*    </FormControl> */}
                {/* </div> */}
            </div>
            {eventsGroupedByMonth.map(({ month, eventsByMonth, amountEnded }) => (
                <div key={`month-${month}`}>
                    <Typography className="text-xl font-bold tracking-tighter">
                        {moment(month, 'YYYY-MM').format('MMMM').substring(0, 1).toUpperCase() +
                            moment(month, 'YYYY-MM').format('MMMM').substring(1).toLowerCase()}
                    </Typography>
                    <Grid container spacing={4} className="!-mt-2">
                        {eventsByMonth.map(event => (
                            <Grid item sm={6} md={4} xl={3} className="w-full">
                                <Card className={clsx('rounded-lg', event.isEnded && 'opacity-75')}>
                                    <CardContent>
                                        <div className={clsx('flex justify-between items-start')}>
                                            <Typography className="text-lg font-bold">
                                                {event.attributes.name}
                                            </Typography>
                                            <Typography
                                                className={clsx(
                                                    'text-xs font-medium me-2 px-2.5 py-0.5 rounded text-nowrap min-w-[5.8rem] ml-2',
                                                    event.isEnded
                                                        ? 'bg-gray-300 text-gray-700'
                                                        : 'bg-primary text-white'
                                                )}
                                            >
                                                {moment(event.attributes.start).format(t('MEDIUM_DATE_FORMAT'))}
                                            </Typography>
                                        </div>
                                        {event.attributes.location && (
                                            <Typography className="text-sm italic text-ellipsis">
                                                {event.attributes.location}
                                            </Typography>
                                        )}
                                        <Typography className="text-sm mt-4 mb-4 overflow-hidden" color="textSecondary">
                                            {event.attributes.description && (
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: event.attributes.description
                                                    }}
                                                    className="ck-content"
                                                />
                                            )}
                                            {!event.attributes.description && t('NO_DESCRIPTION')}
                                        </Typography>
                                        {event.attributes.link && (
                                            <Button
                                                component={Link}
                                                to={event.attributes.link}
                                                variant={event.isEnded ? 'outlined' : 'contained'}
                                            >
                                                {t('SIGN_UP')}
                                            </Button>
                                        )}
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            ))}
        </div>
    );
}
