import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { requestConfig, selectConfigIsLoaded } from './shared/configSlice';
import Loading from '../ui-components/Loading';

export default function ConfigInitializer({ children }) {
    const dispatch = useDispatch();
    const configIsLoaded = useSelector(selectConfigIsLoaded);

    useEffect(() => {
        dispatch(requestConfig());
    }, [dispatch]);

    if (!configIsLoaded) {
        return <Loading fullscreen />;
    }

    return children;
}
