export default function IframeComponent({ pageContent }) {
    return (
        <div
            style={{
                backgroundColor: pageContent?.colors?.backgroundColor || undefined,
                color: pageContent?.colors?.textColor || undefined
            }}
        >
            <div
                className="w-full md:container mx-auto"
                style={{
                    height: 'calc(100vh - 120px)'
                }}
            >
                <iframe
                    src={pageContent.url}
                    width="100%"
                    height="100%"
                    title="iframe content"
                    className="border-none"
                />
            </div>
        </div>
    );
}
