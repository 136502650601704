import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

/* eslint-disable camelcase */

class ApiService {
    constructor() {
        this.uri = process.env.REACT_APP_API_ORIGIN ?? `${document.location.origin}`;
        let language = localStorage.getItem('language');
        if (language) {
            language = JSON.parse(language);
        } else {
            language = { isoCode: 'da' };
        }
        this.axiosService = axios.create({
            baseURL: `${this.uri}/api`,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json'
            },
            params: {
                locale: language.isoCode
            }
        });
    }

    init() {
        this.setInterceptors();
    }

    getUri() {
        return this.uri;
    }

    async post(uri, body) {
        const headers = {};
        if (this.getAccessToken()) {
            headers.Authorization = `Bearer ${this.getAccessToken()}`;
        }
        return this.axiosService
            .post(uri, body instanceof FormData ? body : JSON.stringify(body), {
                headers
            })
            .then(resp => resp.data);
    }

    async get(uri, options) {
        const headers = {};
        if (this.getAccessToken()) {
            headers.Authorization = `Bearer ${this.getAccessToken()}`;
        }
        return this.axiosService
            .get(uri, {
                headers,
                ...options
            })
            .then(resp => resp.data);
    }

    async patch(uri, body) {
        const headers = {};
        if (this.getAccessToken()) {
            headers.Authorization = `Bearer ${this.getAccessToken()}`;
        }
        return this.axiosService
            .patch(uri, JSON.stringify(body), {
                headers
            })
            .then(resp => resp.data);
    }

    async put(uri, body) {
        const headers = {};
        if (this.getAccessToken()) {
            headers.Authorization = `Bearer ${this.getAccessToken()}`;
        }
        return this.axiosService
            .put(uri, JSON.stringify(body), {
                headers
            })
            .then(resp => resp.data);
    }

    async delete(uri) {
        const headers = {};
        if (this.getAccessToken()) {
            headers.Authorization = `Bearer ${this.getAccessToken()}`;
        }
        return this.axiosService
            .delete(uri, {
                headers
            })
            .then(resp => resp.data);
    }

    // eslint-disable-next-line class-methods-use-this
    setInterceptors = () => {
        axios.interceptors.response.use(
            response => {
                return response;
            },
            err => {
                return new Promise((resolve, reject) => {
                    if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
                        // if you ever get an unauthorized response, logout the user
                    }
                    throw err;
                });
            }
        );
    };

    // eslint-disable-next-line class-methods-use-this
    isAuthTokenValid = jwtToken => {
        if (!jwtToken) {
            return false;
        }
        const decoded = jwtDecode(jwtToken);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.warn('access token expired');
            return false;
        }

        return true;
    };

    // eslint-disable-next-line class-methods-use-this
    decode = jwtToken => {
        return jwtDecode(jwtToken);
    };

    // eslint-disable-next-line class-methods-use-this
    getAccessToken = () => {
        return window.localStorage.getItem('jwt_token');
    };

    getImage = image => {
        return `${this.uri}${image}`;
    };
}

const instance = new ApiService();

export default instance;

export { ApiService };
