import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { TableFooter, TablePagination } from '@mui/material';
import clsx from 'clsx';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import apiService from '../services/apiService';

const types = {
    general_assembly: {
        label: 'Generalforsamling',
        classes: 'bg-blue-50 text-blue-700 ring-blue-700/20'
    },
    board_meeting: {
        label: 'Bestyrelsesmøde',
        classes: 'bg-green-50 text-green-700 ring-green-600/20'
    }
};
export default function ReportsComponent({ pageContent }) {
    const [reports, setReports] = useState([]);
    const [tablePage, setTablePage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalCount, setTotalCount] = useState(0);
    const { t } = useTranslation();

    const StyledTable = styled(Table)(({ theme }) => ({
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            '& thead,& tbody,& tfoot,& th,& td,& tr': {
                display: 'block'
            },
            '& thead': {
                position: 'absolute',
                top: '-9999px',
                left: '-9999px'
            },
            '& tr': {
                borderTop: '1px solid #ccc',
                borderRight: '1px solid #ccc',
                borderLeft: '1px solid #ccc'
            },
            '& td': {
                border: 'none',
                borderBottom: '2px solid #eee',
                position: 'relative',
                paddingLeft: '50%'
            },
            '& tbody td:before': {
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: '1rem',
                width: '45%',
                paddingRight: '10px',
                whiteSpace: 'nowrap',
                textAlign: 'left'
            },
            '& tbody td:nth-of-type(1):before': {
                content: pageContent.reports_type === 'all' ? `"${t('REPORTS_TYPE')}"` : `"${t('REPORTS_NAME')}"`
            },
            '& tbody td:nth-of-type(2):before': {
                content: pageContent.reports_type === 'all' ? `"${t('REPORTS_NAME')}"` : `"${t('REPORTS_DATE')}"`
            },
            '& tbody td:nth-of-type(3):before': {
                content: pageContent.reports_type === 'all' ? `"${t('REPORTS_DATE')}"` : `"${t('REPORTS_DOWNLOAD')}"`
            },
            '& tbody td:nth-of-type(4):before': {
                content: `"${t('REPORTS_DOWNLOAD')}"`
            }
        }
    }));

    const handleChangePage = (event, newPage) => {
        setTablePage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setTablePage(0);
    };

    useEffect(() => {
        let filter = '';
        if (pageContent.reports_type !== 'all') {
            filter = `&filters[type][$eq]=${pageContent.reports_type}`;
        }
        apiService
            .get(
                `/reports?sort=date:desc&populate=pdf&pagination[page]=${
                    tablePage + 1
                }&pagination[pageSize]=${rowsPerPage}${filter}`
            )
            .then(resp => {
                setReports(resp.data);
                setTotalCount(resp.meta.pagination.total);
            });
    }, [tablePage, rowsPerPage, pageContent.reports_type]);

    return (
        <div>
            <section className="w-full py-12">
                <div className="container px-4 md:px-6 mx-auto">
                    <TableContainer component={Paper}>
                        <StyledTable>
                            <TableHead>
                                <TableRow>
                                    {pageContent.reports_type === 'all' && <TableCell>{t('REPORTS_TYPE')}</TableCell>}
                                    <TableCell>{t('REPORTS_NAME')}</TableCell>
                                    <TableCell>{t('REPORTS_DATE')}</TableCell>
                                    <TableCell align="right">{t('REPORTS_DOWNLOAD')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reports?.map(report => (
                                    <TableRow
                                        key={`report-${report.id}`}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        {pageContent.reports_type === 'all' && (
                                            <TableCell>
                                                <span
                                                    className={clsx(
                                                        'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ',
                                                        types[report.attributes.type]?.classes ||
                                                            'bg-gray-50 text-gray-600 ring-gray-500/20'
                                                    )}
                                                >
                                                    {t(`REPORTS_TYPE_${report.attributes.type.toUpperCase()}`)}
                                                </span>
                                            </TableCell>
                                        )}
                                        <TableCell>{report.attributes.name}</TableCell>
                                        <TableCell>
                                            {moment(report.attributes.date).format(t('SHORT_DATE_FORMAT'))}
                                        </TableCell>
                                        <TableCell align="right">
                                            <Button
                                                onClick={() => {
                                                    window.open(
                                                        apiService.getImage(report.attributes.pdf.data.attributes.url),
                                                        '_blank'
                                                    );
                                                }}
                                                variant="outlined"
                                                color="primary"
                                                className="w-full"
                                            >
                                                {t('REPORTS_SHOW')}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {reports.length !== rowsPerPage && (
                                    <TableRow style={{ height: 69 * (rowsPerPage - reports.length) }} />
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                        colSpan={4}
                                        count={totalCount}
                                        rowsPerPage={rowsPerPage}
                                        page={tablePage}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page'
                                            },
                                            native: true
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </StyledTable>
                    </TableContainer>
                </div>
            </section>
        </div>
    );
}
