import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login, logoutUser, selectUser } from '../../store/shared/authSlice';

export default function LogoutPage() {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(logoutUser());
    }, [dispatch]);

    useEffect(() => {
        if (!user) {
            navigate('/login');
        }
    }, [navigate, user]);

    return null;
}
