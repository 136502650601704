import {
    Alert,
    AlertTitle,
    Button,
    Card,
    CardContent,
    FormControl,
    Grid,
    Icon,
    InputLabel,
    MenuItem,
    Select,
    Tooltip
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import apiService from '../../services/apiService';
import Loading from '../../ui-components/Loading';
import CustomCKEditor from '../../ui-components/CKEditor';
import allLanguages from '../../translation/languages';
import FlagIcon from '../../ui-components/FlagIcon';
import TextFieldForm from '../../ui-components/TextFieldForm';
import AutocompleteForm from '../../ui-components/AutocompleteForm';
import { showMessage } from '../../store/shared/messageSlice';
import { selectUser } from '../../store/shared/authSlice';
import ConfirmationDialog from '../../ui-components/ConfirmationDialog';

export default function MyPostPage() {
    const { id } = useParams();
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [post, setPost] = useState(null);
    const [isMax, setIsMax] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('danish');
    const [educations, setEducations] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const { t } = useTranslation();
    const { formState, handleSubmit, reset, control } = useForm({
        mode: 'onChange',
        defaultValues: {
            title: '',
            location: '',
            description: '',
            short_description: '',
            type: '',
            educations: []
        }
    });
    const { isDirty, isValid, isSubmitting } = formState;

    const requestPost = useCallback(() => {
        if (id === 'nyt') {
            setPost({
                attributes: {
                    title: {},
                    location: {},
                    description: {},
                    short_description: {},
                    type: '',
                    educations: { data: [] }
                }
            });
            return;
        }
        apiService
            .get(
                `/posts/${id}?noLanguageFormat=true&populate[0]=title&populate[1]=location&populate[2]=description&populate[3]=createdAt&populate[4]=updatedAt&populate[5]=educations&populate[6]=educations.name&populate[7]=user&populate[8]=short_description`
            )
            .then(response => {
                setPost(response.data);
            });
    }, [id]);

    useEffect(() => {
        if (post?.attributes && post.attributes.isOwner === false) {
            navigate('/mine-opslag');
        }
    }, [post, navigate]);

    useEffect(() => {
        apiService
            .get('/educations?populate=name&pagination[limit]=1000000000')
            .then(response => {
                setEducations(response.data.map(e => ({ ...e.attributes, id: e.id })));
            })
            .catch(console.error);
    }, []);

    useEffect(() => {
        if (!post?.attributes || !selectedLanguage || !educations) {
            return;
        }
        reset({
            ...post.attributes,
            educations: post.attributes.educations.data.map(e => educations.find(education => education.id === e.id)),
            title: post.attributes.title?.[selectedLanguage] || '',
            location: post.attributes.location?.[selectedLanguage] || '',
            description: post.attributes.description?.[selectedLanguage] || '',
            short_description: post.attributes.short_description?.[selectedLanguage] || ''
        });
    }, [reset, post, selectedLanguage, educations]);

    useEffect(() => {
        requestPost();
    }, [requestPost]);

    function handleLanguageChange(event) {
        if (isDirty && !window.confirm(t('MY_POST_CHANGES_NOT_SAVED'))) {
            return;
        }
        setSelectedLanguage(event.target.value);
    }

    async function onSubmit(data) {
        if (id === 'nyt') {
            console.log('checking');
            if (data.educations.length === 0 && !confirmDialog) {
                console.log('test');
                reset(undefined, {
                    keepValues: true,
                    keepDirty: true,
                    keepErrors: true,
                    keepDefaultValues: true,
                    keepTouched: true,
                    keepIsValid: true,
                    keepSubmitCount: true
                });
                setConfirmDialog(true);
                return;
            }
            setConfirmDialog(false);
            const response = await apiService.post('/posts', {
                data: {
                    ...data,
                    title: {
                        [selectedLanguage]: data.title
                    },
                    location: {
                        [selectedLanguage]: data.location
                    },
                    description: {
                        [selectedLanguage]: data.description
                    },
                    short_description: {
                        [selectedLanguage]: data.short_description
                    }
                }
            });
            navigate(`/mine-opslag/${response.data.id}`, { replace: true });
            dispatch(
                showMessage({
                    message: t('MY_POST_CREATED'),
                    variant: 'success'
                })
            );
        } else {
            await apiService.put(`/posts/${id}`, {
                data: {
                    ...data,
                    title: {
                        ...post.attributes.title,
                        [selectedLanguage]: data.title
                    },
                    location: {
                        ...post.attributes.location,
                        [selectedLanguage]: data.location
                    },
                    description: {
                        ...post.attributes.description,
                        [selectedLanguage]: data.description
                    },
                    short_description: {
                        ...post.attributes.short_description,
                        [selectedLanguage]: data.short_description
                    }
                }
            });
            requestPost();
            dispatch(
                showMessage({
                    message: t('MY_POST_UPDATED'),
                    variant: 'success'
                })
            );
        }
    }

    async function handleToggleVisibility() {
        if (post.attributes.publishedAt) {
            await apiService
                .put(`/posts/${post.id}`, {
                    data: {
                        publishedAt: null
                    }
                })
                .then(() => {
                    dispatch(showMessage({ message: t('MY_POSTS_VISIBILITY_SHOW'), variant: 'success' }));
                })
                .catch(e => {
                    dispatch(showMessage({ message: e.response.data.error.message, variant: 'error' }));
                });
        } else {
            await apiService
                .put(`/posts/${post.id}`, {
                    data: {
                        publishedAt: new Date().toISOString()
                    }
                })
                .then(() => {
                    dispatch(showMessage({ message: t('MY_POSTS_VISIBILITY_HIDDEN'), variant: 'success' }));
                })
                .catch(e => {
                    dispatch(showMessage({ message: e.response.data.error.message, variant: 'error' }));
                });
        }
        requestPost();
    }

    if (!post) {
        return <Loading fullscreen />;
    }

    return (
        <div className="w-full min-h-screen bg-gray-200 pt-10 p-2 md:p-10">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="flex justify-between">
                            <h2 className="text-2xl font-bold m-0">
                                {id === 'nyt' ? t('MY_POST_CREATE') : t('MY_POST_UPDATE')}
                            </h2>
                            <Paper>
                                <FormControl sx={{ width: 160 }} size="small">
                                    <InputLabel id="select-language">{t('MY_POST_LANGUAGE')}</InputLabel>
                                    <Select
                                        labelId="select-language"
                                        value={selectedLanguage}
                                        label={t('MY_POST_LANGUAGE')}
                                        onChange={handleLanguageChange}
                                    >
                                        {Object.values(allLanguages).map(language => (
                                            <MenuItem key={language.translationKey} value={language.translationKey}>
                                                {language.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Paper>
                        </div>
                    </Grid>
                    {post?.attributes && !post.attributes.publishedAt && (
                        <Grid item xs={12}>
                            <Alert
                                severity="info"
                                action={
                                    <Button
                                        variant="outlined"
                                        onClick={handleToggleVisibility}
                                        disabled={!user?.isPartnerPaid}
                                    >
                                        {t('MY_POST_PUBLISH')}
                                    </Button>
                                }
                            >
                                <AlertTitle>{t('MY_POST_NOT_PUBLISHED_YET_TITLE')}</AlertTitle>
                                {t('MY_POST_NOT_PUBLISHED_YET_DESC')}
                            </Alert>
                        </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                        <Card className="h-full">
                            <div className="px-6 flex items-center">
                                <FlagIcon
                                    className="-mt-2 mr-3"
                                    flagCountryCode={
                                        Object.values(allLanguages).find(l => l.translationKey === selectedLanguage)
                                            .flag
                                    }
                                />
                                <h2 className="text-xl font-bold">{t('MY_POST_INFORMATION')}</h2>
                            </div>
                            <CardContent className="px-6">
                                <div className="flex w-full">
                                    <TextFieldForm
                                        control={control}
                                        name="title"
                                        className="w-full"
                                        label={t('MY_POST_TITLE')}
                                        required
                                        size="small"
                                    />
                                </div>
                                <div className="flex w-full mt-4">
                                    <TextFieldForm
                                        control={control}
                                        name="location"
                                        className="w-full"
                                        label={t('MY_POST_LOCATION')}
                                        required
                                        size="small"
                                    />
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card className="h-full">
                            <div className="px-6 flex items-center">
                                <Tooltip title={t('MY_POST_SAME_FOR_ALL_LANGUAGES')}>
                                    <Icon className="mr-3">language</Icon>
                                </Tooltip>
                                <h2 className="text-xl font-bold">{t('MY_POST_SETTINGS')}</h2>
                            </div>
                            <CardContent className="px-6">
                                <div className="flex w-full">
                                    <Controller
                                        control={control}
                                        rules={{ required: t('FORM_REQUIRED_FIELD') }}
                                        render={({ field }) => (
                                            <FormControl size="small" className="w-full" required>
                                                <InputLabel id="type">{t('MY_POST_TYPE')}</InputLabel>
                                                <Select
                                                    labelId="type"
                                                    value={field.value}
                                                    label={t('MY_POST_TYPE')}
                                                    onChange={field.onChange}
                                                >
                                                    {['STUDENT_JOB', 'FULL_TIME_JOB', 'INTERNSHIP', 'PROJECT'].map(
                                                        type => (
                                                            <MenuItem key={`type-${type}`} value={type}>
                                                                {t(`POST_TYPE_${type.toUpperCase()}`)}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                        name="type"
                                    />
                                </div>
                                <div className="flex w-full mt-4">
                                    <AutocompleteForm
                                        control={control}
                                        name="educations"
                                        className="w-full"
                                        label={t('MY_POST_EDUCATIONS')}
                                        size="small"
                                        multiple
                                        options={educations || []}
                                        loading={educations === null}
                                        getOptionLabel={option => option?.name}
                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                        helperText={t('MY_POST_EDUCATIONS_HELPER')}
                                    />
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <div className="px-6 flex items-center justify-between">
                                <div className="flex items-center">
                                    <FlagIcon
                                        className="-mt-2 mr-3"
                                        flagCountryCode={
                                            Object.values(allLanguages).find(l => l.translationKey === selectedLanguage)
                                                .flag
                                        }
                                    />
                                    <h2 className="text-xl font-bold">{t('MY_POST_SHORT_DESCRIPTION')}</h2>
                                </div>
                                {isMax && (
                                    <Alert severity="error" className="py-0 px-2">
                                        {t('MY_POST_MAX_LENGTH')}
                                    </Alert>
                                )}
                            </div>
                            <CardContent className="px-6">
                                <Controller
                                    name="short_description"
                                    control={control}
                                    rules={{
                                        required: t('FORM_REQUIRED_FIELD')
                                    }}
                                    render={({ field, fieldState }) => (
                                        <CustomCKEditor
                                            data={field.value}
                                            onChange={(event, editor) => {
                                                field.onChange(editor.getData());
                                            }}
                                            basic
                                            maxCharacters={process.env.REACT_APP_MAX_SHORT_DESCRIPTION_LENGTH}
                                            onMax={_isMax => setIsMax(_isMax)}
                                        />
                                    )}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <div className="px-6 flex items-center">
                                <FlagIcon
                                    className="-mt-2 mr-3"
                                    flagCountryCode={
                                        Object.values(allLanguages).find(l => l.translationKey === selectedLanguage)
                                            .flag
                                    }
                                />
                                <h2 className="text-xl font-bold">{t('MY_POST_DESCRIPTION')}</h2>
                            </div>
                            <CardContent className="px-6">
                                <Controller
                                    name="description"
                                    control={control}
                                    rules={{
                                        required: t('FORM_REQUIRED_FIELD')
                                    }}
                                    render={({ field, fieldState }) => (
                                        <CustomCKEditor
                                            data={field.value}
                                            onChange={(event, editor) => {
                                                field.onChange(editor.getData());
                                            }}
                                        />
                                    )}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent className="px-6 !pb-4">
                                <div className="flex justify-between">
                                    <Button
                                        variant="outlined"
                                        onClick={() => navigate(-1)}
                                        startIcon={<Icon>arrow_backwards</Icon>}
                                    >
                                        {t('POST_GO_BACK')}
                                    </Button>
                                    <LoadingButton
                                        variant="contained"
                                        color="primary"
                                        loading={isSubmitting}
                                        disabled={!isDirty || !isValid || isMax || !user?.isPartnerPaid}
                                        type="submit"
                                    >
                                        {id === 'nyt' ? t('MY_POST_CREATE_BUTTON') : t('MY_POST_SAVE_BUTTON')}
                                    </LoadingButton>
                                </div>
                                <ConfirmationDialog
                                    title="Bekræft"
                                    onClose={result => {
                                        setConfirmDialog(false);
                                        if (result) {
                                            handleSubmit(onSubmit)();
                                        }
                                    }}
                                    open={confirmDialog}
                                >
                                    Er du sikker på at du vil oprette et opslag for alle uddannelser?
                                </ConfirmationDialog>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
}
