import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

export default function ConfirmationDialog({
    open,
    onClose,
    title,
    children,
    okButton,
    cancelButton,
    loading,
    disabled,
    fullScreen,
    okButtonType
}) {
    const { t } = useTranslation();

    function handleClose(result) {
        onClose(result);
    }

    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
            fullScreen={fullScreen}
        >
            {!!title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                {cancelButton !== false && (
                    <Button onClick={() => handleClose(false)} variant="outlined">
                        {cancelButton || t('CANCEL')}
                    </Button>
                )}
                <LoadingButton
                    onClick={() => handleClose(true)}
                    variant="contained"
                    color="primary"
                    loading={loading}
                    disabled={disabled}
                    type={okButtonType}
                >
                    {okButton || t('CONFIRM')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
