import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Loading from '../ui-components/Loading';
import apiService from '../services/apiService';

export default function CouncilPage() {
    const { slug } = useParams();
    const [council, setCouncil] = useState(null);
    const [error, setError] = useState(null);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        apiService
            .get(
                `/councils?filters[slug]=${slug}&populate[0]=student_educations&populate[1]=admins&populate[2]=localizations`
            )
            .then(response => {
                setCouncil(response.data?.[0]);
            })
            .catch(err => setError(err));
    }, [slug]);

    return (
        <div
            className="bg-[#E5E7EB]"
            style={{
                minHeight: 'calc(100vh - 120px)'
            }}
        >
            {!council?.attributes?.content && !error && <Loading />}
            {error && (
                <section className="w-full pt-12">
                    <div className="container px-4 md:px-6 mx-auto">
                        {error?.response?.status === 403 && (
                            <div className="flex justify-center items-center flex-col p-20">
                                <SentimentDissatisfiedIcon fontSize="inherit" style={{ fontSize: '10rem' }} />
                                <Typography className="mt-4 text-3xl font-bold">{t('MISSING_ACCESS')}</Typography>
                                <Typography className="mt-4 text-xl">{t('MISSING_ACCESS_TEXT')}</Typography>
                            </div>
                        )}
                        {error?.response?.status !== 403 && (
                            <div className="flex justify-center items-center flex-col p-20">
                                <SentimentDissatisfiedIcon fontSize="inherit" style={{ fontSize: '10rem' }} />
                                <Typography className="mt-4 text-3xl font-bold">{t('UNKNOWN_ERROR')}</Typography>
                            </div>
                        )}
                    </div>
                </section>
            )}
            {council?.attributes?.content && (
                <section className="w-full pt-12">
                    <div className="container px-4 md:px-6 mx-auto">
                        <Paper className="p-6">
                            <div
                                dangerouslySetInnerHTML={{ __html: council.attributes.content }}
                                className="ck-content"
                            />
                        </Paper>
                    </div>
                </section>
            )}
        </div>
    );
}
