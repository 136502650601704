import Paper from '@mui/material/Paper';
import {
    Alert,
    AlertTitle,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import apiService from '../../services/apiService';
import Loading from '../../ui-components/Loading';
import { showMessage } from '../../store/shared/messageSlice';
import { selectUser } from '../../store/shared/authSlice';

export default function FormComponent({ pageContent }) {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const [form, setForm] = useState(null);
    const [shownAnswer, setShownAnswer] = useState(null);
    const [shownAnswerId, setShownAnswerId] = useState('new');
    const [answers, setAnswers] = useState(null);
    const [error, setError] = useState(null);
    const [warning, setWarning] = useState(null);
    const { setValue, handleSubmit, reset, control, formState } = useForm({
        mode: 'onChange',
        defaultValues: {}
    });
    const { isValid, isSubmitting, isSubmitted } = formState;
    const { t } = useTranslation();

    const disableEditing = useMemo(() => {
        if (form?.allow_answer_change) {
            return false;
        }
        if (shownAnswer) {
            return true;
        }
        return false;
    }, [form, shownAnswer]);

    useEffect(() => {
        if (warning === 'maximumAnswers' && answers?.length >= 1) {
            setShownAnswer(answers[0]);
            setShownAnswerId(answers[0].id);
        }
    }, [warning, answers]);

    useEffect(() => {
        if (shownAnswerId && shownAnswerId !== 'new') {
            setShownAnswer(answers.find(answer => answer.id === shownAnswerId));
        } else if (shownAnswerId === 'new') {
            setShownAnswer(null);
        }
    }, [shownAnswerId, answers]);

    useEffect(() => {
        if (form?.max_answers_per_user <= 0) {
            return;
        }
        if (answers?.length >= form?.max_answers_per_user) {
            setWarning('maximumAnswers');
        } else if (answers?.length >= 1) {
            setWarning('answers');
        }
    }, [answers, form]);

    useEffect(() => {
        if (user && form?.uid) {
            apiService.get(`/forms/${form.uid}/answers?pagination[limit]=1000000000`).then(resp => {
                setAnswers(resp);
            });
        }
    }, [user, form]);

    useEffect(() => {
        if (pageContent?.form?.data?.attributes?.uid) {
            apiService
                .get(
                    `/forms/${pageContent?.form?.data?.attributes?.uid}?populate[sent_content]=*&populate[name]=*&populate[content][on][form.text][populate]=*&populate[content][on][form.check-boxes][populate][check_option][populate]=*&populate[content][on][form.radio-boxes][populate][radio_option][populate]=*&populate[content][on][form.radio-boxes][populate][name][populate]=*&populate[content][on][form.check-boxes][populate][name][populate]=*`
                )
                .then(resp => {
                    setForm(resp.data.attributes);
                })
                .catch(e => {
                    setError(e);
                });
        }
    }, [pageContent]);

    useEffect(() => {
        if (form) {
            const _defaultValues = {};
            form.content.forEach(block => {
                if (block.__component === 'form.text') {
                    _defaultValues[block.name] = '';
                }
                if (block.__component === 'form.check-boxes') {
                    _defaultValues[block.name] = [];
                }
                if (block.__component === 'form.radio-boxes') {
                    _defaultValues[block.name] = null;
                }
            });
            if (shownAnswer) {
                shownAnswer.answers.forEach(answer => {
                    if (Array.isArray(answer.value)) {
                        _defaultValues[answer.name] = answer.value.map(v => v.value);
                        return;
                    }
                    _defaultValues[answer.name] = answer.value;
                });
            }
            reset(_defaultValues);
        }
    }, [reset, form, shownAnswer]);

    async function onSubmit(data) {
        if (form?.allow_answer_change && shownAnswer) {
            await apiService
                .patch(`/forms/${form.uid}/answers/${shownAnswerId}`, {
                    answers: Object.entries(data).map(([key, value]) => {
                        if (Array.isArray(value)) {
                            return {
                                __component: 'form-answer.select-answer',
                                name: key,
                                value: value.map(v => ({ value: v }))
                            };
                        }
                        return {
                            __component: 'form-answer.text-answer',
                            name: key,
                            value
                        };
                    })
                })
                .catch(() => {
                    dispatch(
                        showMessage({
                            message: 'Failed to edit form',
                            severity: 'error'
                        })
                    );
                });
        } else {
            await apiService
                .post(`/form-answers`, {
                    form: form.uid,
                    answers: Object.entries(data).map(([key, value]) => {
                        if (Array.isArray(value)) {
                            return {
                                __component: 'form-answer.select-answer',
                                name: key,
                                value: value.map(v => ({ value: v }))
                            };
                        }
                        return {
                            __component: 'form-answer.text-answer',
                            name: key,
                            value
                        };
                    })
                })
                .catch(() => {
                    dispatch(
                        showMessage({
                            message: 'Failed to submit form',
                            severity: 'error'
                        })
                    );
                });
        }
    }

    if (!form && !error) {
        return <Loading fullscreen />;
    }

    return (
        <section className="w-full py-12">
            <div className="max-w-4xl px-4 md:px-6 mx-auto">
                <Paper className="p-6">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={3}>
                            {!error && (
                                <Grid item xs={12}>
                                    <Typography className="text-3xl font-bold tracking-tighter">{form.name}</Typography>
                                </Grid>
                            )}
                            {!isSubmitted && warning && (
                                // eslint-disable-next-line react/jsx-no-useless-fragment
                                <>
                                    {warning === 'maximumAnswers' && (
                                        <Grid item xs={12}>
                                            <Alert
                                                severity="warning"
                                                sx={{
                                                    '&.MuiAlert-root': {
                                                        flexDirection: {
                                                            xs: 'column',
                                                            sm: 'row'
                                                        }
                                                    }
                                                }}
                                                action={
                                                    answers?.length >= 2 && (
                                                        <FormControl className="w-40" size="small">
                                                            <InputLabel id="demo-simple-select-label">
                                                                {t('FORM_ANSWER')}
                                                            </InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                label={t('FORM_ANSWER')}
                                                                value={shownAnswerId}
                                                                onChange={e => {
                                                                    setShownAnswerId(e.target.value);
                                                                }}
                                                            >
                                                                {answers?.map((answer, index) => (
                                                                    <MenuItem value={answer.id}>
                                                                        {t('FORM_ANSWER')} #{index + 1}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    )
                                                }
                                            >
                                                <AlertTitle>{t('FORM_ALREADY_ANSWERED')}</AlertTitle>
                                                {t('FORM_ALREADY_ANSWERED_DESC')}
                                                <br />
                                                {answers?.length >= 2 && t('FORM_MORE_THAN_ONE_ANSWER')}
                                                <br />
                                                {form?.allow_answer_change && t('FORM_CHANGE_ANSWER')}
                                            </Alert>
                                        </Grid>
                                    )}

                                    {warning === 'answers' && (
                                        <Grid item xs={12}>
                                            <Alert
                                                severity="warning"
                                                action={
                                                    <FormControl className="w-40" size="small">
                                                        <InputLabel id="demo-simple-select-label">
                                                            {t('FORM_ANSWER')}
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label={t('FORM_ANSWER')}
                                                            value={shownAnswerId}
                                                            onChange={e => {
                                                                setShownAnswerId(e.target.value);
                                                            }}
                                                        >
                                                            <MenuItem value="new">{t('FORM_NEW_ANSWER')}</MenuItem>
                                                            {answers?.map((answer, index) => (
                                                                <MenuItem value={answer.id}>
                                                                    {t('FORM_ANSWER')} #{index + 1}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                }
                                                sx={{
                                                    '&.MuiAlert-root': {
                                                        flexDirection: {
                                                            xs: 'column',
                                                            sm: 'row'
                                                        }
                                                    }
                                                }}
                                            >
                                                <AlertTitle>{t('FORM_ANSWERED_BEFORE')}</AlertTitle>
                                                {t('FORM_ANSWERED_BEFORE_DESC')}
                                                <br />
                                                {form?.allow_answer_change && t('FORM_CHANGE_ANSWER')}
                                            </Alert>
                                        </Grid>
                                    )}
                                </>
                            )}
                            {error?.response?.status === 401 && (
                                <Grid item xs={12}>
                                    <Typography className="text-lg">{t('FORM_REQUIRE_LOGIN')}</Typography>
                                </Grid>
                            )}
                            {isSubmitted && (
                                <Grid item xs={12}>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: form.sent_content }}
                                        className="ck-content"
                                    />
                                </Grid>
                            )}
                            {!error && !form.accept_answers && (
                                <Grid item xs={12}>
                                    <Typography className="text-lg">{t('FORM_NOT_ACCEPTING_ANSWERS')}</Typography>
                                </Grid>
                            )}
                            {!error &&
                                form.accept_answers &&
                                !isSubmitted &&
                                form.content.map((block, index) => (
                                    <Grid item xs={12} key={`block-${index}`}>
                                        {block.__component === 'form.text' && (
                                            <Controller
                                                control={control}
                                                name={block.name}
                                                rules={{
                                                    required: block.required ? t('FORM_REQUIRED_FIELD') : false,
                                                    pattern: block.is_email
                                                        ? {
                                                              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                                                              message: t('FORM_INVALID_EMAIL')
                                                          }
                                                        : undefined
                                                }}
                                                render={({ field, fieldState }) => (
                                                    // eslint-disable-next-line react/jsx-no-useless-fragment
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            value={field.value || ''}
                                                            required={block.required}
                                                            multiline={block.multiline}
                                                            minRows={block.multiline ? 3 : 1}
                                                            className="w-full"
                                                            label={block.name}
                                                            error={fieldState.invalid}
                                                            helperText={fieldState.error?.message}
                                                            disabled={disableEditing}
                                                        />
                                                    </>
                                                )}
                                            />
                                        )}
                                        {block.__component === 'form.check-boxes' && (
                                            <>
                                                <Typography>{block.name}</Typography>
                                                <Controller
                                                    control={control}
                                                    name={block.name}
                                                    rules={{
                                                        required: block.required ? t('FORM_REQUIRED_FIELD') : false
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        // eslint-disable-next-line react/jsx-no-useless-fragment
                                                        <>
                                                            {block.check_option.map(option => (
                                                                <FormControlLabel
                                                                    key={`checkoption${option.name}`}
                                                                    control={
                                                                        <Checkbox
                                                                            checked={
                                                                                !!field.value?.includes(option.name)
                                                                            }
                                                                        />
                                                                    }
                                                                    disabled={disableEditing}
                                                                    label={option.name}
                                                                    onChange={() => {
                                                                        setValue(
                                                                            block.name,
                                                                            field.value.includes(option.name)
                                                                                ? field.value.filter(
                                                                                      val => val !== option.name
                                                                                  )
                                                                                : [...field.value, option.name],
                                                                            {
                                                                                shouldValidate: true,
                                                                                shouldDirty: true
                                                                            }
                                                                        );
                                                                    }}
                                                                />
                                                            ))}
                                                        </>
                                                    )}
                                                />
                                            </>
                                        )}
                                        {block.__component === 'form.radio-boxes' && (
                                            <>
                                                <Typography>{block.name}</Typography>

                                                <Controller
                                                    control={control}
                                                    name={block.name}
                                                    rules={{
                                                        required: block.required ? t('FORM_REQUIRED_FIELD') : false
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        // eslint-disable-next-line react/jsx-no-useless-fragment
                                                        <RadioGroup
                                                            name={block.name}
                                                            value={field.value || ''}
                                                            onChange={event => {
                                                                setValue(block.name, event.target.value, {
                                                                    shouldValidate: true,
                                                                    shouldDirty: true
                                                                });
                                                            }}
                                                        >
                                                            {block.radio_option.map(option => (
                                                                <FormControlLabel
                                                                    key={`radiooption${option.name}`}
                                                                    value={option.name}
                                                                    control={<Radio />}
                                                                    label={option.name}
                                                                    disabled={disableEditing}
                                                                />
                                                            ))}
                                                        </RadioGroup>
                                                    )}
                                                />
                                            </>
                                        )}
                                    </Grid>
                                ))}
                            {!error && form.accept_answers && !isSubmitted && (
                                <Grid item xs={12}>
                                    <div className="w-full flex justify-end">
                                        <LoadingButton
                                            type="submit"
                                            variant="contained"
                                            disabled={!isValid || disableEditing}
                                            loading={isSubmitting}
                                        >
                                            {form?.allow_answer_change && shownAnswer
                                                ? t('FORM_EDIT_ANSWER')
                                                : t('FORM_SUBMIT_ANSWER')}
                                        </LoadingButton>
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                    </form>
                </Paper>
            </div>
        </section>
    );
}
